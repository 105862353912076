import React, { useContext, useEffect } from "react";
import { Paper, Typography } from "@mui/material";
import { paperStyle } from "../../../assets/styles/Styles";
import NewPerson from "../person/NewPerson";
import NewRejected from "./NewRejected";
import { NewRequestContext, NewRequestContextProvider } from "../../../contexts/NewRequestContext";

export const NewEntry = () => {
  const { activeStep } = useContext(NewRequestContext);

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <NewPerson fromEntry />;
      case 1:
        return <NewRejected />;
      default:
        throw new Error("Paso desconocido");
    }
  }

  return (
    <Paper sx={paperStyle}>
      <Typography component="h1" variant="h4" align="center">
        Nueva entrada
      </Typography>
      {getStepContent(activeStep)}
    </Paper>
  );
};
