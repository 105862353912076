import { useState, useEffect } from "react";
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import { displayToast } from "../helpers/toast";
import { authHeader } from "../helpers/auth";

interface UseAxiosResult<T = any> {
  data: T;
  loading: boolean;
  error: AxiosError | null;
  refetch: (finishAwait?: boolean) => Promise<void>;
}

interface UseAxiosSet<T = any> {
  url: string;
  method?: "get" | "post" | "put" | "delete";
  auth?: boolean;
  initialData?: T;
  data?: any;
  config?: AxiosRequestConfig;
  awaiting?: boolean;
  ignoreData?: boolean;
  msgOnSuccess?: string;
}

const useAxios = <T = any>({
  url,
  method = "get",
  auth = true,
  initialData,
  data,
  config,
  awaiting = false,
  ignoreData = false,
  msgOnSuccess,
}: UseAxiosSet<T>): UseAxiosResult<T> => {
  const [responseData, setResponseData] = useState<T>(initialData || (false as T));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  const { headers } = authHeader();

  const fetchData = async (finishAwait: boolean = false): Promise<void> => {
    setLoading(true);
    if (((method === "post" || method === "put") && !data && !ignoreData) || (awaiting && !finishAwait)) {
      setLoading(false);
      return;
    }

    setError(null);
    try {
      const response: AxiosResponse<T> = await axios({
        method,
        url,
        data,
        headers: auth ? headers : undefined,
        ...config,
      });
      setResponseData(response.data);
      if (msgOnSuccess) {
        displayToast({
          message: msgOnSuccess,
          statusCode: 201,
        });
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        setError(error);
        displayToast(error?.response?.data || error, error);
      } else {
        displayToast({ message: error as string, statusCode: 404 });
        setError(new Error("An unknown error occurred") as AxiosError);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, method, data, config]);

  return {
    data: responseData,
    loading,
    error,
    refetch: (fetchear = true) => fetchData(fetchear),
  };
};

export default useAxios;
