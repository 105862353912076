import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { NewRequestContext } from "../../../contexts/NewRequestContext";
import { areValuesEqual, handleChangeValue, regexFloat } from "../../../helpers/functions";
import { CATEGORY_N, CATEGORY_SUB, PROBLEMATIC } from "../../../assets/constants/translates";
import { LoadingButton } from "@mui/lab";
import { ApplicationType, NewApplicationType, StatusType } from "../../../types/Application";
import useAxios from "../../../hooks/useAxios";
import endpoints from "../../../helpers/endpoints";
import { InputNumber } from "../../../components/Inputs";

export const AddApplicationData = () => {
  const { setActiveStep, application, setApplication } = useContext(NewRequestContext);
  const [newApplication, setNewApplication] = useState<NewApplicationType>({
    category: application?.category ? application.category : "",
    problematic: application?.problematic ? application.problematic : "not_applicable",
    observation: application?.observation ? application.observation : "",
    amount: application?.amount === null ? "" : application.amount,
    unit: application?.unit ? application.unit : "pesos",
    isAccountable: application?.isAccountable,
  });
  const {
    data: newApplicationData,
    loading: loadingPostApplication,
    refetch: postApplication,
  } = useAxios<ApplicationType>({
    url: `${endpoints.updateApplication}/${application.id}`,
    method: "put",
    data: { ...newApplication, amount: newApplication.amount ? newApplication.amount : 0 },
    awaiting: true,
    msgOnSuccess: "Datos guardados",
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (application.status !== StatusType.IN_APP_DATA && areValuesEqual(application, newApplication)) {
      setActiveStep(6);
    } else {
      postApplication();
    }
  };

  useEffect(() => {
    if (newApplicationData) {
      setApplication(newApplicationData);
      setActiveStep(6);
    }
  }, [newApplicationData]);

  useEffect(() => {
    if (!application?.unit) {
      switch (newApplication.category) {
        case "fuel":
          handleChangeValue("unit", "litros de combustible", setNewApplication);
          break;
        case "birthday":
          handleChangeValue("unit", "kilos de carne", setNewApplication);
          break;
        default:
          handleChangeValue("unit", "pesos", setNewApplication);
          break;
      }
    }
  }, [newApplication.category]);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Complete con los datos personales
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel required id="category">
                Categoría
              </InputLabel>
              <Select
                labelId="category"
                value={newApplication.category}
                name="category"
                label="Categoría"
                required
                onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewApplication)}
              >
                <ListSubheader>
                  <strong>Común</strong>
                </ListSubheader>
                {Object.entries(CATEGORY_N).map(([value, text]) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
                <Divider />
                <ListSubheader>
                  <strong>Subsidio</strong>
                </ListSubheader>
                {Object.entries(CATEGORY_SUB).map(([value, text]) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel required id="problematic">
                Problemática
              </InputLabel>
              <Select
                labelId="problematic"
                value={newApplication.problematic}
                name="problematic"
                label="Problemática"
                required
                onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewApplication)}
              >
                {PROBLEMATIC.map((problematic) => (
                  <MenuItem key={problematic.value} value={problematic.value}>
                    {problematic.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={newApplication.observation}
              onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewApplication)}
              required
              name="observation"
              label="Observación y recomendación"
              fullWidth
              multiline
              minRows={6}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InputNumber
              entity="application"
              name="amount"
              setValue={setNewApplication}
              value={newApplication.amount}
              typeNumber={newApplication.unit.toLowerCase().includes("pesos") ? "money" : "decimal"}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Unidad"
              name="unit"
              value={newApplication.unit}
              onChange={(e) => {
                handleChangeValue(e.target.name, e.target.value, setNewApplication);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <FormControl required>
              <FormLabel required>¿Requiere que se presente un comprobante para finalizar la solicitud?</FormLabel>
              <RadioGroup
                row
                name="isAccountable"
                value={newApplication.isAccountable}
                onChange={(e) => {
                  return handleChangeValue(e.target.name, !newApplication.isAccountable, setNewApplication);
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Sí" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              setActiveStep((activeStep) => activeStep - 1);
            }}
            sx={{ mt: 3, ml: 1 }}
            tabIndex={-1}
          >
            Atrás
          </Button>
          <LoadingButton loading={loadingPostApplication} variant="contained" type="submit" sx={{ mt: 3, ml: 1 }}>
            Siguiente
          </LoadingButton>
        </Box>
      </form>
    </React.Fragment>
  );
};
