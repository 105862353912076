import React, { useState, useEffect } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, Filler, ArcElement } from "chart.js";
import { Backdrop, Box, Button, ButtonGroup, CircularProgress, Divider, Grid, Paper, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { paperStyle } from "../../assets/styles/Styles";
import useAxios from "../../hooks/useAxios";
import { TotalStatisticType } from "../../types/Statistic";
import endpoints from "../../helpers/endpoints";
import { Bar, Pie } from "react-chartjs-2";
import { optionsBar, optionsCategories, optionsS_Categories, optionsTotal } from "../../assets/constants/chartsOptions";
import { CATEGORY, CATEGORY_SUB } from "../../assets/constants/translates";
import { useNavigate } from "react-router";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, Filler, ArcElement);

export function Statistics() {
  const actualYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [year, setYear] = useState<number | "">(actualYear);
  const { data: stats, refetch: getDataChart, loading } = useAxios<TotalStatisticType>({ url: `${endpoints.getTotalStats}?year=${year}` });

  const years = Array.from({ length: actualYear - 2022 }, (_, index) => index + 2023);

  const dataBar = {
    labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    datasets: [
      {
        label: "Aceptadas",
        data: stats?.application?.months,
        backgroundColor: "rgba(53, 235, 132, 0.5)",
      },
      {
        label: "Registradas",
        data: stats?.register?.months,
        backgroundColor: "rgba(53, 123, 235, 0.5)",
      },
      {
        label: "Rechazadas",
        data: stats?.rejected?.months,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  const dataTotal = {
    labels: ["Aceptadas", "Registradas", "Rechazadas"],
    datasets: [
      {
        label: "Cantidad de solicitudes",
        data: [stats?.application?.total, stats?.register?.total, stats?.rejected?.total],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
        borderWidth: 1,
      },
    ],
  };
  const dataCategories = {
    labels: stats.category?.map((cat) => CATEGORY[cat.name]) || [],
    datasets: [
      {
        label: "Cantidad de solicitudes",
        data: stats.category?.map((cat) => cat.count),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const dataS_Categories = {
    labels: stats.s_category?.map((cat) => CATEGORY_SUB[cat.name]) || [],
    datasets: [
      {
        label: "Cantidad de solicitudes",
        data: stats.s_category?.map((cat) => cat.count),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    getDataChart();
  }, [year]);

  return (
    <Paper sx={paperStyle} elevation={3}>
      <Typography component="h1" variant="h4" align="center">
        Estadísticas
      </Typography>
      <Divider />
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <ButtonGroup variant="contained">
          {years.map((y) => (
            <LoadingButton disabled={loading || year === y} onClick={() => setYear(y)} key={y}>
              {y}
            </LoadingButton>
          ))}
          <LoadingButton disabled={loading || year === ""} onClick={() => setYear("")}>
            Total
          </LoadingButton>
        </ButtonGroup>
        <Button onClick={() => navigate("./" + (year ? year : "total"))} sx={{ ml: 2 }}>
          Ver reporte detallado
        </Button>
      </Box>
      <Divider />
      {loading ? (
        <Backdrop open>
          <CircularProgress />
        </Backdrop>
      ) : (
        <Grid container spacing={2}>
          {year !== "" && (
            <Grid item xs={12} maxHeight="100vh" display={"flex"} justifyContent={"center"}>
              <Bar options={optionsBar} data={dataBar} />
            </Grid>
          )}
          <Grid item xs={12} maxHeight="100vh" display={"flex"} justifyContent={"center"}>
            <Pie
              options={{
                ...optionsTotal,
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: ({ dataset, dataIndex }) => {
                        const total = dataset.data.reduce((sum, value) => sum + value, 0);
                        const currentValue = dataset.data[dataIndex];
                        const percentage = ((currentValue / total) * 100).toFixed(2);
                        return `${dataTotal.labels[dataIndex]}: ${percentage}%`;
                      },
                    },
                  },
                },
              }}
              data={dataTotal}
            />
          </Grid>
          <Grid item md={6} sm={12} maxHeight="100vh" display={"flex"} justifyContent={"center"}>
            <Pie options={optionsCategories} data={dataCategories} />
          </Grid>
          <Grid item md={6} sm={12} maxHeight="100vh" display={"flex"} justifyContent={"center"}>
            <Pie options={optionsS_Categories} data={dataS_Categories} />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
