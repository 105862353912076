import React, { useContext } from "react";
import { Paper, Stepper, Step, StepLabel, Typography, Backdrop, CircularProgress } from "@mui/material";

import NewPerson from "./operator/person/NewPerson";
import NewApplication from "./operator/application/NewApplication";
import { NewRequestContext } from "../contexts/NewRequestContext";
import { AddCohabitants } from "./operator/application/AddCohabitants";
import { DwellingData } from "./operator/application/DwellingData";
import { NewNote } from "./operator/application/NewNote";
import { AddApplicationData } from "./operator/application/AddApplicationData";
import { FileUploading } from "./operator/application/FileUploading";
import { paperStyle } from "../assets/styles/Styles";
import { Application } from "./operator/application/Application";

const stepsApp = ["Carga del ciudadano", "Datos personales", "Grupo familiar", "Datos de la vivienda", "Nota", "Datos de la solicitud", "Archivos", "Resumen"];

export const NewRequest = () => {
  const { activeStep, setActiveStep, loading, application } = useContext(NewRequestContext);

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <NewPerson />;
      case 1:
        return <NewApplication />;
      case 2:
        return <AddCohabitants />;
      case 3:
        return <DwellingData />;
      case 4:
        return <NewNote />;
      case 5:
        return <AddApplicationData />;
      case 6:
        return <FileUploading />;
      case 7:
        return <Application fromSummary />;
      case 8:
        return <Application fromSummary />;
      default:
        throw new Error("Paso desconocido");
    }
  }

  const handleStep = (step: number) => () => {
    if (!!application.id && (application.status === "complete" || application.status === "success")) {
      setActiveStep(step);
    }
  };

  return loading ? (
    <Backdrop open>
      <CircularProgress />
    </Backdrop>
  ) : (
    <Paper sx={paperStyle}>
      <Typography component="h1" variant="h4" align="center">
        Nueva solicitud
      </Typography>
      <Stepper
        activeStep={activeStep === 8 ? 7 : activeStep}
        alternativeLabel
        nonLinear={!!application.id && (application.status === "complete" || application.status === "success")}
        sx={{ pt: 3, pb: 5, overflowX: "auto" }}
      >
        {stepsApp.map((label, index) => (
          <Step key={label} onClick={handleStep(index)}>
            <StepLabel
              sx={{
                "&:hover": { cursor: !!application.id && (application.status === "complete" || application.status === "success") ? "pointer" : "inherit" },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {getStepContent(activeStep)}
    </Paper>
  );
};
