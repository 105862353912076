import React, { useRef, useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import endpoints from "../helpers/endpoints";
// import { Footer } from "../views/templates/Footer";
import { DataUserType, RolesType } from "../types/User";
import useAxios from "../hooks/useAxios";
import { useLocation } from "react-router";
import { displayToast } from "../helpers/toast";
import { Sidebar } from "../views/layouts/Sidebar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export const PrivateRoute = ({ roles }: { roles: RolesType[] | undefined }) => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const flag = useRef(true);

  useEffect(() => {
    if (state && flag.current) {
      displayToast(state);
      window.history.replaceState(null, "");
      flag.current = false;
    }
  }, [state]);

  const { data: userData, error, loading } = useAxios<DataUserType>({ url: endpoints.getDataUser });

  useEffect(() => {
    if (!localStorage.getItem("jwt")) {
      navigate("/");
    }
    if (error) {
      const msg = error.response?.statusText || error.message;
      localStorage.removeItem("jwt");
      displayToast({ message: msg === "Unauthorized" ? "No tienes permiso para ver esta página" : msg, statusCode: error.response?.status || 400 });
    }
  }, [error]);

  return loading ? (
    <Backdrop open>
      <CircularProgress />
    </Backdrop>
  ) : roles === undefined || roles.includes(userData.role as RolesType) ? (
    <>
      <Sidebar
      // role={userData.role}
      // name={userData.name}
      // lastname={userData.lastname}
      />
      <Container className="home">
        <Outlet context={{ ...userData }} />
      </Container>
      {/* <Footer /> */}
      {/* <Copyright /> */}
    </>
  ) : (
    <Navigate to="/" replace />
  );
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
