import React from "react";
import { useParams } from "react-router";
import { Paper, Typography, Box, CircularProgress, Tooltip, Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import { useApiQuery } from "../../helpers/apiClient";
import endpoints from "../../helpers/endpoints";
import { CATEGORY, PROBLEMATIC } from "../../assets/constants/translates";
import { convertToDate } from "../../helpers/dateFormat";
import { BaseDataGrid } from "../../components/BaseDataGrid";
import { paperStyle } from "../../assets/styles/Styles";
import { Category_N } from "../../types/Application";
import { formatToCurrency } from "../../helpers/functions";

export const ApplicationsByCategory = () => {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      description: "Código de solicitud",
      width: 100,
      renderCell: (params) => (
        <Link href={`/solicitud/${params.value}`} underline="hover">
          {params.value}
        </Link>
      ),
    },
    {
      field: "created_at",
      headerName: "Fecha",
      description: "Fecha de entrada",
      width: 120,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const [date, hour] = convertToDate(params.row.created_at);
        return (
          <Tooltip title={hour}>
            <span>{date}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "name",
      headerName: "Solicitante",
      description: "Nombre del solicitante",
      minWidth: 200,
      flex: 40,
    },
    {
      field: "problematic",
      headerName: "Problemática",
      description: "Problemática de la solicitud",
      minWidth: 150,
      flex: 40,
      renderCell: (params) => PROBLEMATIC.find((value) => value.value === params.row.problematic)?.text,
    },
    {
      field: "amount",
      headerName: "Total entregado",
      minWidth: 50,
      flex: 20,
      renderCell: (params) => (unit === "pesos" ? formatToCurrency.format(params.row.amount) : `${params.row.amount} ${unit?.replaceAll("-", " ")}`),
    },
  ];

  const { year, category, unit } = useParams();

  const { data: applications, isFetching } = useApiQuery<any[]>(
    ["applicationsByCategory"],
    `${endpoints.getApplicationsByParams}?year=${year === "total" ? "" : year}&category=${category}&unit=${unit}`
  );

  return (
    <Paper sx={paperStyle}>
      <Typography variant="h5" gutterBottom>
        {CATEGORY[category as Category_N] || ""} - {year === "total" ? "Todos" : year} - {unit?.replaceAll("-", " ")}
      </Typography>
      {isFetching ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <BaseDataGrid
          rows={applications || []}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10]}
        />
      )}
    </Paper>
  );
};
