import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Box, Tooltip, TextField, Stack } from "@mui/material";
import { GridColDef, GridEventListener } from "@mui/x-data-grid";
import { Delete, Edit, PostAdd } from "@mui/icons-material";
import { MuiFileInput } from "mui-file-input";

import useAxios from "../../../hooks/useAxios";
import endpoints from "../../../helpers/endpoints";
import { ApplicationType } from "../../../types/Application";
import { CATEGORY, DNI_TYPE, PROBLEMATIC, STATUS } from "../../../assets/constants/translates";
import { convertToDate } from "../../../helpers/dateFormat";
import { ModalNew } from "../../../components/ModalNew";
import { FileType, NewResourceType, ResourceType } from "../../../types/Resource";
import { handleChangeValue, toNameFormat } from "../../../helpers/functions";
import { allowedFiles } from "../../../assets/constants/filepond";
import { ActionButton } from "../../../components/ActionButton";
import { BaseDataGrid } from "../../../components/BaseDataGrid";
import { PersonType } from "../../../types/Person";

type ApplicationsProps = {
  type: "complete" | "success" | "progress";
};

const INITIAL_RESOURCE: NewResourceType = {
  type: FileType.RECEIPT,
  description: "",
  applicationId: null,
};

const createBaseColumns = (): GridColDef[] => [
  {
    field: "id",
    headerName: "ID",
    description: "Código de solicitud",
    width: 60,
  },
  {
    field: "createdAt",
    headerName: "Fecha",
    description: "Fecha de entrada",
    width: 100,
    align: "center",
    headerAlign: "center",
    valueGetter: (value) => new Date(value),
    renderCell: (params: { row: ApplicationType }) => {
      const [date, hour] = convertToDate(params.row?.createdAt);
      return (
        <Tooltip title={hour}>
          <span>{date}</span>
        </Tooltip>
      );
    },
  },
];

const createProgressColumns = (): GridColDef[] => [
  {
    field: "status",
    headerName: "Estado",
    description: "Estado en el que se encuentra el formulario",
    minWidth: 100,
    flex: 50,
    valueGetter: (params: ApplicationType) => STATUS[params?.status],
    renderCell: (params: { row: ApplicationType }) => STATUS[params.row?.status],
  },
];

const createDetailColumns = (): GridColDef[] => [
  {
    field: "category",
    headerName: "Categoría",
    description: "Categoría de la solicitud",
    minWidth: 50,
    flex: 15,
    renderCell: (params: { row: ApplicationType }) => CATEGORY[params.row?.category],
  },
  {
    field: "problematic",
    headerName: "Problemática",
    description: "Problemática de la solicitud",
    minWidth: 50,
    flex: 15,
    renderCell: (params: { row: ApplicationType }) => PROBLEMATIC.find((value) => value.value === params.row?.problematic)?.text,
  },
  {
    field: "observation",
    headerName: "Observaciones",
    description: "Observaciones de la solicitud",
    minWidth: 50,
    flex: 40,
  },
  {
    field: "amount",
    headerName: "Monto",
    description: "Monto otorgado",
    minWidth: 50,
    flex: 10,
    renderCell: (params) => `${params.row?.amount || 0} ${params.row?.unit}`,
  },
];

export const Applications: React.FC<ApplicationsProps> = ({ type }) => {
  const navigate = useNavigate();
  const [modalState, setModalState] = useState({
    receiptOpen: false,
    deleteOpen: false as number | false,
    error: "",
  });
  const [newResource, setNewResource] = useState<NewResourceType>(INITIAL_RESOURCE);
  const [receiptFile, setReceiptFile] = useState<File | null>(null);
  const [form, setForm] = useState<FormData>();
  const [deleteAppId, setDeleteAppId] = useState<number>();

  const {
    data: applications,
    refetch: refetchApplications,
    loading: loadingApplications,
  } = useAxios<ApplicationType[]>({
    url: type !== "progress" ? `${endpoints.getApplications}?status=${type}` : endpoints.getApplicationsInProgress,
    initialData: [],
  });

  const {
    refetch: deleteApp,
    loading: loadingDelete,
    data: deletedApp,
  } = useAxios<ApplicationType>({
    url: `${endpoints.deleteApplications}/${deleteAppId}`,
    awaiting: true,
    method: "delete",
    msgOnSuccess: "Solicitud eliminada",
  });

  const {
    data: receiptData,
    refetch: postReceipt,
    loading: loadingReceipt,
  } = useAxios<ResourceType>({
    url: `${endpoints.resourceBase}/${endpoints.uploadFile}/${newResource.type}/${newResource.applicationId}`,
    method: "post",
    awaiting: true,
    data: form,
    msgOnSuccess: "Comprobante cargado",
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setModalState((prev) => ({ ...prev, error: "" }));

    if (!receiptFile) {
      setModalState((prev) => ({ ...prev, error: "Debes subir el comprobante" }));
      return;
    }

    const formData = new FormData();
    formData.append("files", receiptFile);
    formData.append("description", newResource.description as string);
    setForm(formData);
  };

  const handleDelete = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDeleteAppId(Number(modalState.deleteOpen));
  };

  const handleChangeFile = (file: File | null) => {
    setModalState((prev) => ({ ...prev, error: "" }));
    if (file === null || allowedFiles.includes(file.type) || file.type.startsWith("image/")) {
      setReceiptFile(file);
    } else {
      setModalState((prev) => ({ ...prev, error: "El tipo de archivo no es permitido" }));
    }
  };

  const handleModalClose = () => {
    setModalState((prev) => ({ ...prev, receiptOpen: false }));
    setNewResource(INITIAL_RESOURCE);
    setReceiptFile(null);
    setModalState((prev) => ({ ...prev, error: "" }));
  };

  const handleRowClick: GridEventListener<"rowClick"> = (params, event) => {
    if (type === "progress") {
      navigate(`../nueva-solicitud/${params.row?.id}`);
      return;
    }

    const target = event.target as HTMLElement;
    if (target.nodeName === "path") return;

    if (target.getAttribute("aria-label") === "agregar-comprobante") {
      addReceipt(params.row?.id);
    } else {
      navigate(`../solicitud/${params.row?.id}`);
    }
  };

  const addReceipt = (id: number) => {
    setNewResource((prev) => ({ ...prev, applicationId: id }));
    setModalState((prev) => ({ ...prev, receiptOpen: true }));
  };

  const baseColumns = createBaseColumns();
  const typeSpecificColumns = type === "progress" ? createProgressColumns() : createDetailColumns();

  const actionColumn: GridColDef = {
    field: "actions",
    headerName: "Acciones",
    minWidth: 60,
    type: "actions",
    renderCell: (params) => (
      <Stack direction="row" marginX={2}>
        {type === "complete" && <ActionButton title="Agregar comprobante" onClick={() => addReceipt(params.row?.id)} icon={<PostAdd />} />}
        {type !== "progress" && <ActionButton title="Editar" link={`../editar/${params.row?.id}`} icon={<Edit />} color="warning" />}
        <ActionButton title="Eliminar" onClick={() => setModalState((prev) => ({ ...prev, deleteOpen: params.row?.id }))} icon={<Delete />} color="error" />
      </Stack>
    ),
  };

  const personColumn: GridColDef = {
    field: "person",
    headerName: "Ciudadano",
    description: "Persona",
    minWidth: 100,
    flex: 20,
    valueGetter: (value: PersonType) => {
      const { name, lastName, dni } = value;
      return `${lastName} ${name} - ${dni}`;
    },
    renderCell: (params: { row: ApplicationType }) => {
      const { name, lastName, dniType, dni } = params.row?.person;
      return (
        <Tooltip title={`${DNI_TYPE.find((value) => value.value === dniType)?.text as string}: ${dni}`}>
          <span>{toNameFormat(`${lastName}, ${name}`)}</span>
        </Tooltip>
      );
    },
  };

  const columns = [...baseColumns, ...typeSpecificColumns, personColumn, actionColumn].filter(Boolean);

  useEffect(() => {
    if (form) {
      postReceipt();
    }
  }, [form]);

  useEffect(() => {
    if (receiptData) {
      handleModalClose();
      refetchApplications();
    }
  }, [receiptData]);

  useEffect(() => {
    if (deleteAppId) {
      deleteApp();
    }
  }, [deleteAppId]);

  useEffect(() => {
    if (deletedApp) {
      setModalState((prev) => ({ ...prev, deleteOpen: false }));
      refetchApplications();
    }
  }, [deletedApp]);

  const getTitle = () => {
    switch (type) {
      case "progress":
        return "Solicitudes en progreso";
      case "complete":
        return "Solicitudes pendientes de rendición";
      default:
        return "Historial de solicitudes";
    }
  };

  return (
    <Box width="100%">
      <Typography variant="h3">{getTitle()}</Typography>
      <Paper sx={{ width: "100%" }} elevation={3}>
        <BaseDataGrid rows={applications} columns={columns} loading={loadingApplications} onRowClick={handleRowClick} />
      </Paper>
      <ModalNew
        open={modalState.receiptOpen}
        handleClose={handleModalClose}
        handleSubmit={handleSubmit}
        fieldsDialog={{
          title: "Agregar comprobante",
          accept: "Guardar",
          acceptDisabled: !receiptFile,
        }}
        loading={loadingReceipt}
      >
        <MuiFileInput
          value={receiptFile}
          onChange={handleChangeFile}
          placeholder="Click para subir comprobante"
          helperText={modalState.error}
          error={modalState.error !== ""}
          fullWidth
        />
        <TextField
          margin="dense"
          value={newResource.description}
          name="description"
          onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewResource)}
          label="Descripción"
          type="text"
          variant="outlined"
          fullWidth
          autoFocus
        />
      </ModalNew>
      <ModalNew
        open={Boolean(modalState.deleteOpen)}
        handleClose={() => setModalState((prev) => ({ ...prev, deleteOpen: false }))}
        handleSubmit={handleDelete}
        fieldsDialog={{
          title: "¿Está seguro que quiere eliminar la solicitud?",
          description: "Si la elimina no se podrá recuperar. Los datos del ciudadano se conservarán.",
          accept: "Eliminar",
          iconTitle: <Delete />,
        }}
        loading={loadingDelete}
      />
    </Box>
  );
};
