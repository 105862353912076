import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";

type Props = {
  title: string;
  icon: ReactNode;
  link?: string;
  color?: "primary" | "inherit" | "default" | "secondary" | "error" | "info" | "success" | "warning";
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  size?: "small" | "medium" | "large";
};

export const ActionButton = ({ title, icon, link = "", color = "primary", disabled = false, onClick, size = "medium" }: Props) => {
  return (
    <Tooltip
      title={title}
      disableInteractive
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -8],
              },
            },
          ],
        },
      }}
    >
      <IconButton component={Link} to={link} color={color} disabled={disabled} onClick={onClick} size={size}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};
