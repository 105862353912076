import { ApplicationType } from "./Application";
import { UserType } from "./User";

export enum FileType {
  DNI_FRONT = "dni_front",
  DNI_BACK = "dni_back",
  BUDGET = "budget",
  PRESCRIPTION = "prescription",
  OTHER = "other",
  RECEIPT = "receipt",
}

export type NewResourceType = {
  type: FileType;
  description?: string;
  applicationId: number | null;
};

export type ResourceType = {
  id: number;
  type: FileType;
  name: string;
  description?: string;
  application: ApplicationType;
  operator: UserType;
};

export type GetResourceType = {
  [FileType.BUDGET]: ResourceType[];
  [FileType.PRESCRIPTION]: ResourceType[];
  [FileType.OTHER]: ResourceType[];
  [FileType.RECEIPT]: ResourceType[];
};

type FileTypeMulter = {
  fieldname: string;
  originalname: string;
  encoding: string;
  mimetype: string;
  size: number;
  destination: string;
  filename: string;
  path: string;
};

export type ResponseResource = {
  success: boolean;
  message: string;
  files?: FileTypeMulter[];
  error?: any;
};
