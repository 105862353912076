import React, { useContext, useState, useEffect } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, Grid, TextField, Button, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { handleChangeValue } from "../../../helpers/functions";
import useAxios from "../../../hooks/useAxios";
import endpoints from "../../../helpers/endpoints";
import { NewRequestContext } from "../../../contexts/NewRequestContext";
import { RejectedType, NewRejectedType } from "../../../types/Rejected";
import { REASON } from "../../../assets/constants/translates";
import { PersonType } from "../../../types/Person";

export default function NewRejected() {
  const { setActiveStep, person, setPerson, rejected, setRejected } = useContext(NewRequestContext);
  const [newRejected, setNewRejected] = useState<NewRejectedType>();
  const [isEntry, setIsEntry] = useState(true);
  const { data: entryData, loading } = useAxios<RejectedType[]>({
    url: endpoints.uploadRejected,
    method: "post",
    data: newRejected,
    msgOnSuccess: isEntry ? "Entrada guardada" : "Rechazo cargado",
  });

  const handleCheck = () => {
    if (!isEntry) {
      handleChangeValue("reason", undefined, setRejected);
    } else {
      handleChangeValue("reason", 0, setRejected);
    }
    setIsEntry(!isEntry);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isEntry) {
      setNewRejected({ personId: person.id, reason: 0, ...rejected });
    } else {
      setNewRejected({ personId: person.id, ...rejected });
    }
  };

  useEffect(() => {
    if (entryData) {
      setPerson({} as PersonType);
      setRejected({} as RejectedType);
      setActiveStep(0);
    }
  }, [entryData]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <FormControl required>
            <RadioGroup row name="isAccountable" value={isEntry} onChange={handleCheck}>
              <FormControlLabel value={true} control={<Radio />} label="Entrada" />
              <FormControlLabel value={false} control={<Radio />} label="Rechazo" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {!isEntry && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="reason">Razón</InputLabel>
              <Select
                labelId="reason"
                value={rejected.reason}
                name="reason"
                label="Razón"
                onChange={(e) => handleChangeValue(e.target.name, e.target.value, setRejected)}
                required
              >
                {Object.entries(REASON).map(([value, text]) => {
                  if (parseInt(value) !== 0) {
                    return (
                      <MenuItem key={value} value={parseInt(value)}>
                        {text}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box mb={3}>
            <TextField
              value={rejected.description}
              onChange={(e) => handleChangeValue(e.target.name, e.target.value, setRejected)}
              required
              name="description"
              label={isEntry ? "Descripción del motivo de la entrada" : "Descripción del motivo del rechazo"}
              fullWidth
              multiline
              minRows={6}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={() => setActiveStep(0)} sx={{ mt: 3, ml: 1 }}>
          Atrás
        </Button>
        <LoadingButton color="success" variant="contained" type="submit" loading={loading} sx={{ mt: 3, ml: 1 }}>
          {isEntry ? "Guardar atención" : "Guardar rechazo"}
        </LoadingButton>
      </Box>
    </form>
  );
}
