import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";

import { ModalNew } from "./ModalNew";
import { InitialJobType, JobType } from "../types/Job";
import { NewFormType } from "../types/Form";
import { NewNoteType } from "../types/Note";
import endpoints from "../helpers/endpoints";
import useAxios from "../hooks/useAxios";

interface NewJobType {
  edit?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: (finishAwait?: boolean | undefined) => Promise<void>;
  setData?: React.Dispatch<React.SetStateAction<NewFormType>>;
  initialData?: InitialJobType;
  jobs: JobType[];
  setNameJob?: React.Dispatch<React.SetStateAction<string>>;
}

const initialState: InitialJobType = { id: undefined, name: "" };

export const NewJob = ({ edit, open, setOpen, refetch, setData, initialData, jobs, setNameJob }: NewJobType) => {
  const [newJob, setNewJob] = useState<InitialJobType>(initialState);
  const [postNewJobData, setPostNewJobData] = useState<InitialJobType | null>(null);
  const [putNewJobData, setPutNewJobData] = useState<InitialJobType | null>(null);
  const { data: modifyJobResponse, loading: loadingPutJob } = useAxios<JobType>({
    url: `${endpoints.updateJob}/${initialData?.id}`,
    data: putNewJobData,
    method: "put",
    msgOnSuccess: "Ocupación actualizada",
  });
  const { data: newJobResponse, loading: loadingNewJob } = useAxios<JobType>({
    url: endpoints.newJob,
    data: postNewJobData,
    method: "post",
    msgOnSuccess: "Ocupación creada",
  });

  function handleSubmit(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();
    if (initialData && edit) {
      setPutNewJobData({ name: newJob.name });
    } else {
      setPostNewJobData(newJob);
    }
  }

  const handleClose = () => {
    setNewJob({ id: undefined, name: "" });
    setPutNewJobData(null);
    setPostNewJobData(null);
    setOpen(false);
  };

  function setValue(setFunction: any, value: any, field: string, isNew: boolean = false) {
    if (!isNew) {
      setFunction((prevState: NewNoteType) => {
        return { ...prevState, [field]: value };
      });
    } else {
      setFunction(value);
    }
  }

  useEffect(() => {
    if (initialData && edit) {
      setNewJob(initialData);
    }
  }, [initialData]);

  useEffect(() => {
    if (!Array.isArray(newJobResponse)) {
      refetch();
      if (setNameJob) {
        setNameJob(newJobResponse.name);
      }
    }
  }, [newJobResponse]);

  useEffect(() => {
    if (newJob.name) {
      refetch();
      handleClose();
    }
  }, [modifyJobResponse]);

  useEffect(() => {
    if (newJobResponse) {
      if (setData && !edit) {
        setData((prevState: NewFormType) => {
          return {
            ...prevState,
            jobId: newJobResponse.id,
          };
        });
      }
      handleClose();
    }
  }, [jobs]);

  return (
    <ModalNew
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      fieldsDialog={{ title: edit ? "Editar ocupación" : "Nueva ocupación", accept: edit ? "Guardar" : "Cargar" }}
      loading={loadingNewJob || loadingPutJob}
    >
      <TextField
        required
        margin="dense"
        value={newJob.name}
        onChange={(event) => setValue(setNewJob, { name: event.target.value }, "jobId", true)}
        label="Nombre"
        type="text"
        sx={{ width: "400px" }}
        variant="standard"
        autoFocus
      />
    </ModalNew>
  );
};
