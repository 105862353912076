import React, { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import useAxios from "../../hooks/useAxios";
import endpoints from "../../helpers/endpoints";
import "../../assets/styles/login.css";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/login.css";
import loginImg from "../../assets/images/side-img.svg";
import { UserLoginType } from "../../types/User";

export const Login = () => {
  const [user, setUser] = useState({ email: "", password: "" });
  const [body, setBody] = useState<{ email: string; password: string }>();
  const { data, loading } = useAxios<UserLoginType | {}>({
    url: endpoints.login,
    method: "post",
    data: body,
    auth: false,
    initialData: {},
  });
  const navigate = useNavigate();

  const handleFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setUser((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setBody({ email: user.email, password: user.password });
  }

  useEffect(() => {
    if (data.hasOwnProperty("jwt")) {
      const userData = data as UserLoginType;
      window.localStorage.setItem("jwt", JSON.stringify(userData.jwt));
      window.localStorage.setItem("welcome", `¡Bienvenido ${userData.user.name} ${userData.user.lastName}!`);
      window.location.replace("/dashboard");
    }
  }, [data]);

  return (
    <>
      <div className="container">
        <div className="forms-container">
          <div className="signin-signup">
            <form onSubmit={handleSubmit} className="sign-in-form">
              <h2 className="title">Iniciar sesión</h2>
              <div className="input-field">
                <i className="fa-solid fa-fingerprint"></i>
                <input onChange={handleFieldChange} name="email" type="text" placeholder="Correo electrónico" />
              </div>
              <div className="input-field">
                <i className="fas fa-lock"></i>
                <input onChange={handleFieldChange} type="password" name="password" placeholder="Contraseña" />
              </div>
              <LoadingButton type="submit" className="btn solid" loading={loading} disabled={user.email === "" || user.password === ""}>
                Iniciar sesión
              </LoadingButton>
            </form>
          </div>
        </div>
      </div>
      <div className="panels-container">
        <div className="panel left-panel">
          <img src={loginImg} className="image" alt="Hombre observando notas" />
        </div>
      </div>
    </>
  );
};
