import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  TextFieldVariants,
  Autocomplete,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers/locales";
import { NumericFormat, PatternFormat } from "react-number-format";

import { toSpanish } from "../helpers/toSpanish";
import { handleChangeNestedValue, handleChangeRowValue, handleChangeValue } from "../helpers/functions";

type InputType = {
  grid?: number | { sm?: number; md?: number; lg?: number };
  name: string;
  entity: string;
  value: any;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  req?: boolean;
  type?: "nested" | "row";
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  index?: number; // Added for row type
};

const commonProps: { margin: "dense" | "normal" | "none"; variant: TextFieldVariants; fullWidth: boolean } = {
  margin: "dense",
  variant: "outlined",
  fullWidth: true,
};

function getGridSizes(grid?: number | { sm?: number; md?: number; lg?: number }) {
  return {
    sm: !grid || typeof grid === "number" ? 12 : grid.sm || 12,
    md: !grid ? 12 : typeof grid === "number" ? grid : grid.md || 12,
    lg: !grid ? 12 : typeof grid === "number" ? grid : grid.lg || 12,
  };
}

export const InputField = (props: InputType & { email?: boolean; multi?: boolean }) => {
  const grid = getGridSizes(props.grid);

  return (
    <Grid item xs={12} sm={grid.sm} md={grid.md} lg={grid.lg}>
      <TextField
        {...commonProps}
        value={props.value}
        name={props.name}
        onChange={(e) => {
          if (props.type === "nested") {
            handleChangeNestedValue(e.target.name, props.entity, e.target.value, props.setValue);
          } else if (props.type === "row" && props.index !== undefined) {
            handleChangeRowValue(props.index, e.target.name, e.target.value, props.setValue);
          } else {
            handleChangeValue(e.target.name, e.target.value, props.setValue);
          }
        }}
        label={toSpanish(props.entity, props.name)}
        type={props.email ? "email" : "text"}
        disabled={props.disabled}
        required={props.req}
        error={props.error}
        helperText={props.helperText}
        multiline={props.multi}
        minRows={props.multi ? 2 : 1}
      />
    </Grid>
  );
};

export const InputNumber = (props: InputType & { typeNumber?: "money" | "int" | "decimal" | "percentage" }) => {
  const type = props.typeNumber || "int";
  const grid = getGridSizes(props.grid);

  return (
    <Grid item xs={12} sm={grid.sm} md={grid.md} lg={grid.lg}>
      <NumericFormat
        {...commonProps}
        name={props.name}
        value={props.value}
        onValueChange={(value) => {
          let newValue = type === "int" ? value.value : value.floatValue;
          if (props.type === "nested") {
            handleChangeNestedValue(props.name, props.entity, newValue, props.setValue);
          } else if (props.type === "row" && props.index !== undefined) {
            handleChangeRowValue(props.index, props.name, newValue, props.setValue);
          } else {
            handleChangeValue(props.name, newValue, props.setValue);
          }
        }}
        customInput={TextField}
        label={toSpanish(props.entity, props.name)}
        disabled={props.disabled}
        required={props.req}
        error={props.error}
        helperText={props.helperText}
        allowNegative={false}
        allowLeadingZeros={type === "int"}
        decimalSeparator=","
        allowedDecimalSeparators={["."]}
        thousandSeparator={type !== "int" ? "." : false}
        decimalScale={type === "int" ? 0 : 2}
        InputProps={{ startAdornment: props.value && type === "money" ? "$" : "", endAdornment: props.value && type === "percentage" ? "%" : "" }}
        fixedDecimalScale={type === "money"}
      />
    </Grid>
  );
};

export const InputPatternNumber = (props: InputType & { format: string }) => {
  const grid = getGridSizes(props.grid);

  return (
    <Grid item xs={12} sm={grid.sm} md={grid.md} lg={grid.lg}>
      <PatternFormat
        {...commonProps}
        name={props.name}
        value={props.value}
        onChange={(newValue) => {
          if (props.type === "nested") {
            handleChangeNestedValue(props.name, props.entity, newValue, props.setValue);
          } else if (props.type === "row" && props.index !== undefined) {
            handleChangeRowValue(props.index, props.name, newValue, props.setValue);
          } else {
            handleChangeValue(props.name, newValue, props.setValue);
          }
        }}
        customInput={TextField}
        label={toSpanish(props.entity, props.name)}
        disabled={props.disabled}
        required={props.req}
        error={props.error}
        helperText={props.helperText}
        valueIsNumericString={true}
        format={props.format}
        mask="_"
      />
    </Grid>
  );
};

export const InputDate = (props: InputType & { ef?: boolean }) => {
  const grid = getGridSizes(props.grid);

  return (
    <Grid item xs={12} sm={grid.sm} md={grid.md} lg={grid.lg}>
      <FormControl fullWidth required={props.req} sx={{ mt: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DatePicker
            name={props.name}
            label={toSpanish(props.entity, props.name)}
            value={props.value}
            onChange={(newDate) => {
              if (props.type === "nested") {
                handleChangeNestedValue(props.name, props.entity, newDate, props.setValue);
              } else if (props.type === "row" && props.index !== undefined) {
                handleChangeRowValue(props.index, props.name, newDate, props.setValue);
              } else {
                handleChangeValue(props.name, newDate, props.setValue);
              }
            }}
            disabled={props.disabled}
            disableFuture={!props.ef}
            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
            slotProps={{ openPickerButton: { tabIndex: -1 }, textField: { required: props.req, error: props.error, helperText: props.helperText } }}
          />
        </LocalizationProvider>
      </FormControl>
    </Grid>
  );
};

export const InputCheck = (props: InputType) => {
  const grid = getGridSizes(props.grid);

  return (
    <Grid item xs={12} sm={grid.sm} md={grid.md} lg={grid.lg}>
      <FormControlLabel
        label={toSpanish(props.entity, props.name)}
        required={props.req}
        disabled={props.disabled}
        control={
          <Checkbox
            name={props.name}
            checked={props.value}
            onChange={(e) => {
              if (props.type === "nested") {
                handleChangeNestedValue(e.target.name, props.entity, e.target.checked, props.setValue);
              } else if (props.type === "row" && props.index !== undefined) {
                handleChangeRowValue(props.index, e.target.name, e.target.checked, props.setValue);
              } else {
                handleChangeValue(e.target.name, e.target.checked, props.setValue);
              }
            }}
            disabled={props.disabled}
            required={props.req}
          />
        }
      />
    </Grid>
  );
};

export const InputRadio = (props: InputType) => {
  const grid = getGridSizes(props.grid);

  return (
    <Grid item xs={12} sm={grid.sm} md={grid.md} lg={grid.lg}>
      <FormControl required={props.req} disabled={props.disabled}>
        <FormLabel id={"radio-" + props.name}>{toSpanish(props.entity, props.name)}</FormLabel>
        <RadioGroup
          row
          name={props.name}
          aria-labelledby={"radio-" + props.name}
          value={props.value ? "yes" : "no"}
          onChange={(e) => {
            if (props.type === "nested") {
              handleChangeNestedValue(e.target.name, props.entity, (e.target as HTMLInputElement).value === "yes", props.setValue);
            } else if (props.type === "row" && props.index !== undefined) {
              handleChangeRowValue(props.index, e.target.name, (e.target as HTMLInputElement).value === "yes", props.setValue);
            } else {
              handleChangeValue(e.target.name, (e.target as HTMLInputElement).value === "yes", props.setValue);
            }
          }}
        >
          <FormControlLabel value="yes" control={<Radio />} label="SÍ" />
          <FormControlLabel value="no" control={<Radio />} label="NO" />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export const InputSelect = (props: InputType & { iterable: { value: string | number; text: string }[] }) => {
  const grid = getGridSizes(props.grid);

  return (
    <Grid item xs={12} sm={grid.sm} md={grid.md} lg={grid.lg}>
      <FormControl fullWidth sx={{ mt: 1 }}>
        <InputLabel id={props.name} required={props.req}>
          {toSpanish(props.entity, props.name)}
        </InputLabel>
        <Select
          labelId={props.name}
          value={props.value}
          name={props.name}
          label={toSpanish(props.entity, props.name)}
          required={props.req}
          onChange={(e) => {
            if (props.type === "nested") {
              handleChangeNestedValue(e.target.name, props.entity, e.target.value, props.setValue);
            } else if (props.type === "row" && props.index !== undefined) {
              handleChangeRowValue(props.index, e.target.name, e.target.value, props.setValue);
            } else {
              handleChangeValue(e.target.name, e.target.value, props.setValue);
            }
          }}
          disabled={props.disabled}
          inputProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {props.iterable.map((element) => (
            <MenuItem key={element.value} value={element.value}>
              {element.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

type InputAutocompleteType = {
  options: any[];
  getOptionLabel: (option: any) => string;
  loading?: boolean;
  clearable?: boolean;
};

export const InputAutocomplete = (props: InputType & InputAutocompleteType) => {
  const grid = getGridSizes(props.grid);

  return (
    <Grid item xs={12} sm={grid.sm} md={grid.md} lg={grid.lg}>
      <Autocomplete
        loading={props.loading}
        options={props.options}
        getOptionLabel={props.getOptionLabel}
        value={props.options[props.options.findIndex((op) => op.id === props.value)] || null}
        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: any | null) => {
          if (newValue) {
            if (props.type === "nested") {
              handleChangeNestedValue(props.name + "Id", props.entity, newValue?.id || null, props.setValue);
            } else if (props.type === "row" && props.index !== undefined) {
              handleChangeRowValue(props.index, props.name + "Id", newValue?.id || null, props.setValue);
            } else {
              handleChangeValue(props.name + "Id", newValue?.id || null, props.setValue);
            }
          }
        }}
        sx={{ width: "100%", mt: 1 }}
        openOnFocus
        autoSelect
        disableClearable={!props.clearable}
        disabled={props.disabled}
        renderInput={(params) => (
          <TextField {...params} label={toSpanish(props.entity, props.name)} required={props.req} error={props.error} helperText={props.helperText} />
        )}
      />
    </Grid>
  );
};
