import { FormType } from "./Form";
import { PersonType } from "./Person";
import { UserType } from "./User";
import { NoteType } from "./Note";
import { ResourceType } from "./Resource";

export type Status = "in_cohabitants" | "in_form_dwelling" | "in_note" | "in_app_data" | "in_files" | "complete" | "success";
export enum StatusType {
  IN_COHABITANTS = "in_cohabitants",
  IN_FORM_DWELLING = "in_form_dwelling",
  IN_NOTE = "in_note",
  IN_APP_DATA = "in_app_data",
  IN_FILES = "in_files",
  COMPLETE = "complete",
  SUCCESS = "success",
}

export type Category =
  | "im_waiting"
  | "birthday"
  | "fuel"
  | "commodity"
  | "burial"
  | "various_supplies"
  | "medication"
  | "tickets"
  | "spare_parts"
  | "water_tank"
  | "glasses"
  | "terrain"
  | "voucher"
  | "other"
  | "s_transport"
  | "s_electricity"
  | "s_medication"
  | "s_birthday"
  | "s_gas"
  | "s_medical_tests"
  | "s_building_materials"
  | "s_entrepreneurial_equipment"
  | "s_rent_payment"
  | "s_other";

export type Category_N =
  | "im_waiting"
  | "birthday"
  | "fuel"
  | "commodity"
  | "burial"
  | "various_supplies"
  | "medication"
  | "tickets"
  | "spare_parts"
  | "water_tank"
  | "glasses"
  | "terrain"
  | "voucher"
  | "other";

export type Category_S =
  | "s_transport"
  | "s_electricity"
  | "s_medication"
  | "s_birthday"
  | "s_gas"
  | "s_medical_tests"
  | "s_building_materials"
  | "s_entrepreneurial_equipment"
  | "s_rent_payment"
  | "s_other";

type Problematic =
  | "not_applicable"
  | "health"
  | "pregnancy"
  | "anses"
  | "judiciary"
  | "upi"
  | "disease"
  | "for_benefit"
  | "low_income"
  | "disability"
  | "gender_violence"
  | "police"
  | "university"
  | "underweight";

export type CreateApplicationType = {
  personId: number;
  formId: number;
};
export type NewApplicationType = {
  category: Category | "";
  problematic: Problematic;
  observation: string;
  amount: number | "";
  unit: string;
  isAccountable: boolean;
};
export type ApplicationType = {
  id: number;
  createdAt: string;
  updatedAt: string;
  operator: UserType;
  person: PersonType;
  form: FormType;
  note: NoteType;
  status: Status;
  category: Category;
  problematic: Problematic;
  resources: ResourceType[];
  observation: string;
  amount: number;
  unit: string;
  isAccountable: boolean;
};
