import React, { createContext, useState, ReactNode, useEffect } from "react";
import { useParams } from "react-router";

import { PersonType } from "../types/Person";
import { NewRejectedType } from "../types/Rejected";
import { DwellingType, InitialFormType } from "../types/Form";
import { CohabitantType } from "../types/Cohabitant";
import { NoteType } from "../types/Note";
import { ApplicationType, StatusType } from "../types/Application";
import useAxios from "../hooks/useAxios";
import endpoints from "../helpers/endpoints";
import { getNumberStep } from "../helpers/functions";

interface NewRequestContextType {
  loading: boolean;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  person: PersonType;
  setPerson: React.Dispatch<React.SetStateAction<PersonType>>;
  rejected: NewRejectedType;
  setRejected: React.Dispatch<React.SetStateAction<NewRejectedType>>;
  form: InitialFormType;
  setForm: React.Dispatch<React.SetStateAction<InitialFormType>>;
  cohabitants: CohabitantType[];
  setCohabitants: React.Dispatch<React.SetStateAction<CohabitantType[]>>;
  dwelling: DwellingType;
  setDwelling: React.Dispatch<React.SetStateAction<DwellingType>>;
  note: NoteType;
  setNote: React.Dispatch<React.SetStateAction<NoteType>>;
  application: ApplicationType;
  setApplication: React.Dispatch<React.SetStateAction<ApplicationType>>;
}

const initialRejected: NewRejectedType = {
  description: "",
};

export const NewRequestContext = createContext<NewRequestContextType>({
  loading: true,
  activeStep: 0,
  setActiveStep: () => {},
  person: {} as PersonType,
  setPerson: () => {},
  rejected: initialRejected,
  setRejected: () => {},
  form: {} as InitialFormType,
  setForm: () => {},
  cohabitants: [] as CohabitantType[],
  setCohabitants: () => {},
  dwelling: {} as DwellingType,
  setDwelling: () => {},
  note: {} as NoteType,
  setNote: () => {},
  application: {} as ApplicationType,
  setApplication: () => {},
});

export const NewRequestContextProvider = ({ children }: { children: ReactNode }) => {
  const { idApplication } = useParams();

  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [person, setPerson] = useState<PersonType>({} as PersonType);
  const [rejected, setRejected] = useState<NewRejectedType>(initialRejected);
  const [form, setForm] = useState<InitialFormType>({} as InitialFormType);
  const [cohabitants, setCohabitants] = useState<CohabitantType[]>([]);
  const [dwelling, setDwelling] = useState<DwellingType>({} as DwellingType);
  const [note, setNote] = useState<NoteType>({} as NoteType);
  const [application, setApplication] = useState<ApplicationType>({} as ApplicationType);
  const [actualApp, setActualApp] = useState<null | string>(null);

  const { data: applicationData, refetch: getApplication } = useAxios<ApplicationType | false>({
    url: `${endpoints.getApplicationById}/${actualApp}`,
    awaiting: true,
  });
  const { data: lastApp, refetch: getLastApp } = useAxios<ApplicationType | false>({
    url: `${endpoints.getLastApplicationByPersonId}/${person.id}`,
    awaiting: true,
  });

  useEffect(() => {
    if (idApplication) {
      setActualApp(idApplication);
    } else {
      setLoading(false);
    }
  }, [idApplication]);

  useEffect(() => {
    if (actualApp) {
      getApplication();
    }
  }, [actualApp]);

  useEffect(() => {
    if (person.id) {
      getLastApp();
    }
  }, [person]);

  useEffect(() => {
    if (lastApp) {
      if (!applicationData) {
        setForm({
          id: -1,
          createdAt: lastApp.form.createdAt,
          updatedAt: lastApp.form.updatedAt,
          address: lastApp.form.address,
          maritalStatus: lastApp.form.maritalStatus,
          job: lastApp.form.job,
          workplace: lastApp.form.workplace,
          earnings: lastApp.form.earnings,
          education: lastApp.form.education,
        });
      } else if (applicationData.status !== "complete" && applicationData.status !== "success") {
        setForm({
          id: applicationData.form.id,
          createdAt: applicationData.form.createdAt,
          updatedAt: applicationData.form.updatedAt,
          address: applicationData.form.address || lastApp.form.address,
          maritalStatus: applicationData.form.maritalStatus || lastApp.form.maritalStatus,
          job: applicationData.form.job || lastApp.form.job,
          workplace: applicationData.form.workplace || lastApp.form.workplace,
          earnings: applicationData.form.earnings || lastApp.form.earnings,
          education: applicationData.form.education || lastApp.form.education,
        });
        setCohabitants(applicationData.status === StatusType.IN_COHABITANTS ? lastApp.form.cohabitants : applicationData.form.cohabitants);
        setDwelling({
          material: applicationData.form.material || lastApp.form.material,
          roof: applicationData.form.roof || lastApp.form.roof,
          tenure: applicationData.form.tenure || lastApp.form.tenure,
          rentAmount: applicationData.form.rentAmount || lastApp.form.rentAmount || "",
          hasElectricity: applicationData.form.hasElectricity || lastApp.form.hasElectricity,
          waterSupply: applicationData.form.waterSupply || lastApp.form.waterSupply,
          heatSource: applicationData.form.heatSource || lastApp.form.heatSource,
        });
        if (applicationData.note) {
          setNote(applicationData.note);
        } else {
          setNote({ ...lastApp.note, date: new Date().toISOString().split("T")[0] });
        }
        if (applicationData.category) {
          setApplication(applicationData);
        } else {
          setApplication({
            ...applicationData,
            category: lastApp.category,
            problematic: lastApp.problematic,
            observation: lastApp.observation,
            amount: lastApp.amount,
            unit: lastApp.unit,
            isAccountable: lastApp.isAccountable,
          });
        }
      }
      setLoading(false);
    }
  }, [lastApp, applicationData]);

  useEffect(() => {
    if (applicationData) {
      setPerson(applicationData.person);
      setActiveStep(getNumberStep(applicationData));
      if (applicationData.status === "complete" || applicationData.status === "success") {
        setPerson(applicationData.person);
        setForm({
          id: applicationData.form.id,
          createdAt: applicationData.form.createdAt,
          updatedAt: applicationData.form.updatedAt,
          address: applicationData.form.address,
          maritalStatus: applicationData.form.maritalStatus,
          job: applicationData.form.job,
          workplace: applicationData.form.workplace,
          earnings: applicationData.form.earnings,
          education: applicationData.form.education,
        });
        setCohabitants(applicationData.form.cohabitants);
        setDwelling({
          material: applicationData.form.material || "material",
          roof: applicationData.form.roof || "metal",
          tenure: applicationData.form.tenure || "owned",
          rentAmount: applicationData.form.rentAmount === null ? "" : applicationData.form.rentAmount,
          hasElectricity: applicationData.form.hasElectricity || true,
          waterSupply: applicationData.form.waterSupply || "potable_water",
          heatSource: applicationData.form.heatSource || "gas_stove",
        });
        setNote(applicationData.note);
        setApplication(applicationData);
        setLoading(false);
      }
    }
  }, [applicationData]);

  const value: NewRequestContextType = {
    loading,
    activeStep,
    setActiveStep,
    person,
    setPerson,
    rejected,
    setRejected,
    form,
    setForm,
    cohabitants,
    setCohabitants,
    dwelling,
    setDwelling,
    note,
    setNote,
    application,
    setApplication,
  };

  return <NewRequestContext.Provider value={value}>{children}</NewRequestContext.Provider>;
};
