import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import "dayjs/locale/es";

import { NewPersonType, PersonType } from "../../../types/Person";
import { handleChangeValue } from "../../../helpers/functions";
import useAxios from "../../../hooks/useAxios";
import endpoints from "../../../helpers/endpoints";
import { NewRequestContext } from "../../../contexts/NewRequestContext";
import { CATEGORY, CATEGORY_SUB, DNI_TYPE, REASON, STATUS } from "../../../assets/constants/translates";
import { ApplicationType, Category_N, Category_S } from "../../../types/Application";
import { convertToDate, ordenarPorFechaDescendente } from "../../../helpers/dateFormat";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const initialPerson: NewPersonType = {
  id: undefined,
  name: "",
  lastName: "",
  dniType: "dni",
  dni: "",
  birthDate: null,
  phoneNumber: "",
};

type HistoricalType = {
  id: number;
  date: string;
  category: string;
  status: string;
  amount: number | null;
  unit: string | null;
};

export default function NewPerson({ fromEntry }: { fromEntry?: boolean }) {
  const { setActiveStep, person, setPerson, application, setApplication } = useContext(NewRequestContext);
  const [newPerson, setNewPerson] = useState<NewPersonType>(initialPerson);
  const [searchDni, setSearchDni] = useState("");
  const [newPersonId, setNewPersonId] = useState<number | null>(null);
  const [historialPerson, setHistorialPerson] = useState<HistoricalType[]>([]);
  const {
    data: personDataByDni,
    loading: loadingGetPerson,
    refetch: getPerson,
  } = useAxios<PersonType[]>({
    url: endpoints.getPersons,
    config: { params: { dni: searchDni } },
    awaiting: true,
  });
  const {
    data: newPersonData,
    loading: loadingPostPerson,
    refetch: postPerson,
  } = useAxios<PersonType>({
    url: endpoints.uploadPerson,
    method: "post",
    data: newPerson,
    awaiting: true,
    msgOnSuccess: "Persona creada",
  });
  const { data: updatedApplication, refetch: putApplication } = useAxios<ApplicationType>({
    url: `${endpoints.updateApplication}/${application.id}`,
    method: "put",
    data: { personId: newPersonId },
    awaiting: true,
    msgOnSuccess: "Persona asignada",
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Si no existe la persona, hace un post para crearla
    if (!newPerson.id) {
      postPerson();
    } else {
      if (!fromEntry && Object.keys(application).length > 0 && newPerson?.id !== application?.person?.id) {
        setNewPersonId(newPerson.id);
      } else {
        setPerson(personDataByDni[0]);
        setActiveStep(1);
      }
    }
  };

  useEffect(() => {
    if (newPersonData) {
      setPerson(newPersonData);
      if (!fromEntry && !application) {
        setNewPersonId(newPersonData.id);
      } else {
        setActiveStep(1);
      }
    }
  }, [newPersonData]);

  useEffect(() => {
    if (newPersonId) {
      putApplication();
    }
  }, [newPersonId]);

  useEffect(() => {
    if (updatedApplication) {
      setPerson(updatedApplication.person);
      // setApplication(updatedApplication);
      setActiveStep(1);
    }
  }, [updatedApplication]);

  useEffect(() => {
    if (personDataByDni && personDataByDni.length !== 0) {
      const historical: HistoricalType[] = [];
      personDataByDni[0].applications.map((app) =>
        historical.push({
          id: app.id,
          date: app.createdAt,
          category: CATEGORY[app.category as Category_N] || (CATEGORY_SUB[app.category as Category_S] && " (subsidio)") || "En progreso",
          status: STATUS[app.status],
          amount: app.amount,
          unit: app.unit,
        })
      );
      personDataByDni[0].rejecteds.map((rej) =>
        historical.push({
          id: rej.id,
          date: rej.createdAt,
          category: rej.reason === 0 ? "Entrada" : "Rechazado",
          status: rej.reason === 0 ? rej.description : REASON[rej.reason],
          amount: null,
          unit: null,
        })
      );
      setHistorialPerson(ordenarPorFechaDescendente(historical));
      setNewPerson({ ...personDataByDni[0], birthDate: dayjs(personDataByDni[0].birthDate, "YYYY-MM-DD") });
    } else {
      setNewPerson({
        id: undefined,
        name: "",
        lastName: "",
        dniType: "dni",
        dni: newPerson.dni,
        birthDate: null,
        phoneNumber: "",
      });
    }
  }, [personDataByDni]);

  useEffect(() => {
    if (searchDni) {
      getPerson();
    }
  }, [searchDni]);

  useEffect(() => {
    if (newPerson.dni.length >= 7) {
      setSearchDni(newPerson.dni);
    }
  }, [newPerson.dni]);

  useEffect(() => {
    if (person.id) {
      setNewPerson({
        id: person.id,
        name: person.name,
        lastName: person.lastName,
        dniType: person.dniType,
        dni: person.dni,
        birthDate: dayjs(person.birthDate, "YYYY-MM-DD"),
        phoneNumber: person.phoneNumber,
      });
    }
  }, [person]);

  return (
    <React.Fragment>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>
          Complete el formulario con los datos del solicitante
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="dni-type">Tipo de Documento</InputLabel>
              <Select
                labelId="dni-type"
                value={newPerson.dniType}
                name="dniType"
                label="Tipo de Documento"
                required
                onChange={(e) => {
                  handleChangeValue("dni", "", setNewPerson);
                  handleChangeValue(e.target.name, e.target.value, setNewPerson);
                }}
                disabled={loadingGetPerson || (personDataByDni && personDataByDni.length !== 0)}
              >
                {DNI_TYPE.map((dni) => (
                  <MenuItem key={dni.value} value={dni.value}>
                    {dni.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              value={newPerson.dni}
              onChange={(e) => {
                if (newPerson.dniType === "dni" && e.target.value.match("[^0-9]")) {
                  return;
                }
                setHistorialPerson([]);
                handleChangeValue(e.target.name, e.target.value, setNewPerson);
              }}
              inputProps={newPerson.dniType === "dni" ? { minLength: 7, maxLength: 8 } : undefined}
              InputProps={{
                endAdornment: loadingGetPerson && (
                  <InputAdornment position="end">
                    <CircularProgress />
                  </InputAdornment>
                ),
              }}
              required
              name="dni"
              label="Documento"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mb={3}>
              <TextField
                value={newPerson.name}
                onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewPerson)}
                required
                name="name"
                label="Nombre/s"
                fullWidth
                disabled={loadingGetPerson || (personDataByDni && personDataByDni.length !== 0)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mb={3}>
              <TextField
                value={newPerson.lastName}
                onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewPerson)}
                required
                name="lastName"
                label="Apellido/s"
                fullWidth
                disabled={loadingGetPerson || (personDataByDni && personDataByDni.length !== 0)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
              <DatePicker
                label="Fecha de nacimiento"
                value={newPerson.birthDate}
                onChange={(newDate) => handleChangeValue("birthDate", newDate, setNewPerson)}
                disableFuture
                localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                slotProps={{ textField: { required: true }, openPickerButton: { tabIndex: -1 } }}
                disabled={loadingGetPerson || (personDataByDni && personDataByDni.length !== 0)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={8}>
            <TextField
              name="phoneNumber"
              label="Celular"
              value={newPerson.phoneNumber}
              inputProps={{ minLength: 8, maxLength: 10 }}
              onChange={(e) => handleChangeValue(e.target.name, e.target.value || null, setNewPerson)}
              fullWidth
              disabled={loadingGetPerson || (personDataByDni && personDataByDni.length !== 0)}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2, mt: 3 }}>
          <Alert severity="info">
            {newPerson.id ? "Vas a continuar con una persona ya cargada en el sistema" : "Se procederá a cargar a la nueva persona y luego continuar"}
          </Alert>
          <Box display="flex" gap={1} flexDirection="row-reverse">
            <LoadingButton color="success" variant="contained" type="submit" loading={loadingPostPerson} disabled={loadingGetPerson}>
              {fromEntry ? "Continuar con la entrada" : "Continuar con la solicitud"}
            </LoadingButton>
          </Box>
        </Box>
      </form>
      {historialPerson.length > 0 && (
        <>
          <Divider />
          <Typography variant="h5">Historial de solicitudes de la persona</Typography>
          <List>
            {historialPerson.map((hist) => (
              <ListItem key={hist.id} disablePadding>
                <ListItemButton
                  component="a"
                  target="_BLANK"
                  href={(hist.unit ? "/solicitud/" : hist.category === "En progreso" ? "/nueva-solicitud/" : "/entrada/") + hist.id}
                >
                  <ListItemText
                    primary={`${convertToDate(hist.date)} - ${hist.category} ${hist.unit ? `(otorgado ${hist.amount} ${hist.unit})` : ""}`}
                    secondary={hist.status}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </React.Fragment>
  );
}
