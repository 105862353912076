import { ReactElement } from "react";
import { Grid, TextField, Typography } from "@mui/material";

type FieldType = {
  description: string;
  text: string | ReactElement;
  xs: number;
};

export const Field = ({ description, text, xs }: FieldType) => (
  <Grid item xs={xs}>
    <Typography variant="h6" color="gray">
      {description}
    </Typography>
    <Typography variant="h5">{text}</Typography>
  </Grid>
);
