import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { areValuesEqual, handleChangeValue, regexFloat } from "../../../helpers/functions";
import { HEAT_SOURCE, MATERIAL, ROOF, TENURE, WATER_SUPPLY } from "../../../assets/constants/translates";
import { NewRequestContext } from "../../../contexts/NewRequestContext";
import useAxios from "../../../hooks/useAxios";
import endpoints from "../../../helpers/endpoints";
import { DwellingType } from "../../../types/Form";
import { StatusType } from "../../../types/Application";

export const DwellingData = () => {
  const { setActiveStep, application, dwelling, setDwelling, form } = useContext(NewRequestContext);
  const [newForm, setNewForm] = useState<DwellingType>({
    material: dwelling?.material ? dwelling.material : "material",
    roof: dwelling?.roof ? dwelling.roof : "metal",
    tenure: dwelling?.tenure ? dwelling.tenure : "owned",
    rentAmount: dwelling?.rentAmount ? dwelling.rentAmount : "",
    hasElectricity: dwelling?.hasElectricity ? dwelling.hasElectricity : true,
    waterSupply: dwelling?.waterSupply ? dwelling.waterSupply : "potable_water",
    heatSource: dwelling?.heatSource ? dwelling.heatSource : "gas_stove",
  });
  const {
    data: newFormData,
    loading: loadingPostForm,
    refetch: postForm,
  } = useAxios<DwellingType>({
    url: `${endpoints.updateDwelling}/${form.id}`,
    method: "put",
    data: { ...newForm, rentAmount: newForm.tenure !== "rented" ? null : newForm.rentAmount },
    awaiting: true,
    msgOnSuccess: "Datos guardados",
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (application.form?.material && application.status !== StatusType.IN_FORM_DWELLING && areValuesEqual(newForm, dwelling)) {
      setActiveStep(4);
    } else {
      postForm();
    }
  };

  useEffect(() => {
    if (newFormData) {
      setDwelling(newFormData);
      setActiveStep(4);
    }
  }, [newFormData]);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Complete con las condiciones habitacionales
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel required id="material">
                Material de la vivienda
              </InputLabel>
              <Select
                labelId="material"
                value={newForm.material}
                name="material"
                label="Material de la vivienda"
                required
                onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewForm)}
              >
                {MATERIAL.map((materia) => (
                  <MenuItem key={materia.value} value={materia.value}>
                    {materia.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel required id="roof">
                Techo de la vivienda
              </InputLabel>
              <Select
                labelId="roof"
                value={newForm.roof}
                name="roof"
                label="Techo de la vivienda"
                required
                onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewForm)}
              >
                {ROOF.map((roof) => (
                  <MenuItem key={roof.value} value={roof.value}>
                    {roof.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel required id="tenure">
                Tenencia de la vivienda
              </InputLabel>
              <Select
                labelId="tenure"
                value={newForm.tenure}
                name="tenure"
                label="Tenencia de la vivienda"
                required
                onChange={(e) => {
                  if (e.target.value !== "rented") {
                    handleChangeValue("rentAmount", "", setNewForm);
                  }
                  handleChangeValue(e.target.name, e.target.value, setNewForm);
                }}
              >
                {TENURE.map((tenure) => (
                  <MenuItem key={tenure.value} value={tenure.value}>
                    {tenure.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {newForm.tenure === "rented" && (
            <Grid item xs={12} md={3}>
              <TextField
                label="Importe del alquiler"
                name="rentAmount"
                value={newForm.rentAmount}
                onChange={(e) => {
                  if (regexFloat.test(e.target.value)) {
                    handleChangeValue(e.target.name, e.target.value, setNewForm);
                  }
                }}
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <FormControl required>
              <FormLabel required>Servicio eléctrico</FormLabel>
              <RadioGroup
                row
                name="hasElectricity"
                value={newForm.hasElectricity}
                onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewForm)}
              >
                <FormControlLabel value={true} control={<Radio />} label="Sí" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel required id="waterSupply">
                Abastecimiento de agua
              </InputLabel>
              <Select
                labelId="waterSupply"
                value={newForm.waterSupply}
                name="waterSupply"
                label="Abastecimiento de agua"
                required
                onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewForm)}
              >
                {WATER_SUPPLY.map((waterSupply) => (
                  <MenuItem key={waterSupply.value} value={waterSupply.value}>
                    {waterSupply.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel required id="heatSource">
                Fuente calórica
              </InputLabel>
              <Select
                labelId="heatSource"
                value={newForm.heatSource}
                name="heatSource"
                label="Fuente calórica"
                required
                onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewForm)}
              >
                {HEAT_SOURCE.map((heatSource) => (
                  <MenuItem key={heatSource.value} value={heatSource.value}>
                    {heatSource.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              setActiveStep((activeStep) => activeStep - 1);
            }}
            sx={{ mt: 3, ml: 1 }}
            tabIndex={-1}
          >
            Atrás
          </Button>
          <LoadingButton loading={loadingPostForm} variant="contained" type="submit" sx={{ mt: 3, ml: 1 }}>
            Siguiente
          </LoadingButton>
        </Box>
      </form>
    </React.Fragment>
  );
};
