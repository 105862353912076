import { useParams } from "react-router";
import { Backdrop, Box, CircularProgress, Grid, Link, Paper, Typography } from "@mui/material";

import useAxios from "../../../hooks/useAxios";
import endpoints from "../../../helpers/endpoints";
import { RejectedType } from "../../../types/Rejected";
import { Field } from "../../../components/Field";
import { NotFound } from "../../layouts/NotFound";
import { DNI_TYPE, REASON } from "../../../assets/constants/translates";
import { convertToDate, convertToFullDate } from "../../../helpers/dateFormat";

export const Rejected = () => {
  const { idRejected } = useParams();
  const {
    data: dataRejected,
    loading: loadingRejected,
    error: errorRejected,
  } = useAxios<RejectedType>({
    url: `${endpoints.getRejectedById}/${idRejected}`,
  });

  return loadingRejected ? (
    <Backdrop open>
      <CircularProgress />
    </Backdrop>
  ) : errorRejected ? (
    <NotFound />
  ) : (
    <Box width="100%">
      <Typography variant="h3">Solicitud rechazada #{dataRejected.id}</Typography>
      <Paper sx={{ width: "100%", p: 2 }} elevation={3}>
        <Grid container spacing={2}>
          <Field description="Fecha" text={convertToFullDate(dataRejected.createdAt)} xs={4} />
          <Field description="Operador" text={`${dataRejected.operator.lastName}, ${dataRejected.operator.name}`} xs={8} />
          <Field description="Motivo" text={REASON[dataRejected.reason]} xs={4} />
          <Field description="Descripción" text={dataRejected.description} xs={8} />
          <Field
            description="Ciudadano"
            text={
              <Link underline="hover" href={`../ciudadano/${dataRejected.person.id}`}>
                {dataRejected.person.lastName}, {dataRejected.person.name}
              </Link>
            }
            xs={10}
          />
          <Field description={DNI_TYPE.find((value) => value.value === dataRejected.person.dniType)?.text as string} text={dataRejected.person.dni} xs={2} />
          <Field description="Fecha de nacimiento" text={convertToDate(dataRejected.person.birthDate)[0]} xs={6} />
          <Field description="Celular" text={dataRejected.person.phoneNumber || "N/A"} xs={6} />
        </Grid>
      </Paper>
    </Box>
  );
};
