import { toast } from "react-toastify";

type TypeToast = {
  message: string | string[];
  statusCode: number;
};

export const displayToast = (data: TypeToast, error?: any) => {
  if (error) {
    console.error(error);
  }
  let type: "info" | "success" | "warning" | "error" | "default" = "error";
  if (data.statusCode < 200) {
    type = "info";
  } else if (data.statusCode < 300) {
    type = "success";
  } else if (data.statusCode < 400) {
    type = "warning";
  } else if (data.statusCode >= 400) {
    type = "error";
  }

  toast(typeof data.message === "string" || typeof data.message === "undefined" ? data.message : data.message.join("\n"), {
    type: type,
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
  });

  // if (data.message === 'No tienes permitido realizar esta acción') {

  // }
};
