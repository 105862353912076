export const propsFilepond = {
  labelIdle: "Arrastra el archivo o haz click para buscar",
  labelFileTypeNotAllowed: "Extensión de archivo inválida",
  labelFileLoading: "Cargando",
  labelFileLoadError: "Ocurrió un error mientras se cargaba",
  labelFileProcessing: "Subiendo archivo",
  labelFileProcessingComplete: "Subida completada",
  labelFileProcessingAborted: "Subida cancelada",
  labelFileProcessingError: "Error durante la subida",
  labelFileProcessingRevertError: "Error al revertir la subida del archivo",
  labelFileRemoveError: "Error al remover",
  labelInvalidField: "Archivo inválido",
  fileValidateTypeLabelExpectedTypes: "No se permite esta extensión",
  labelFileWaitingForSize: "Calculando el tamaño del archivo",
  labelFileSizeNotAvailable: "No se pudo calcular el tamaño del archivo",
  labelMaxFileSizeExceeded: "Tamaño de archivo excedido",
  labelMaxFileSize: "El máximo permitido es {filesize}",
  labelTapToCancel: "Toca para cancelar",
  labelTapToRetry: "Toca para reintentar",
  labelTapToUndo: "Toca para deshacer",
  labelButtonRemoveItem: "Remover",
  labelButtonAbortItemLoad: "Abortar",
  labelButtonRetryItemLoad: "Reintentar",
  labelButtonAbortItemProcessing: "Cancelar",
  labelButtonUndoItemProcessing: "Deshacer",
  labelButtonRetryItemProcessing: "Reintentar",
  labelButtonProcessItem: "Cargar",
};

export const allowedFiles = ["application/pdf", "image/*", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
