export const optionsBar = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Cantidad de solicitudes",
    },
  },
  x: {
    stacked: true,
  },
  y: {
    stacked: true,
  },
};
export const optionsTotal = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Interacciones totales con el sistema",
    },
  },
};
export const optionsLine = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Suma de dinero distribuido",
    },
  },
};
export const optionsCategories = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Solicitudes por categoría",
    },
  },
};
export const optionsS_Categories = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Solicitudes por subsidios",
    },
  },
};
