import React, { useContext, useState, useEffect } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import "dayjs/locale/es";

import { fullDate } from "../../../assets/constants/dateFormats";
import { NewRequestContext } from "../../../contexts/NewRequestContext";
import { areValuesEqual, handleChangeValue } from "../../../helpers/functions";
import { NewNoteType, NoteType } from "../../../types/Note";
import endpoints from "../../../helpers/endpoints";
import useAxios from "../../../hooks/useAxios";
import { DNI_TYPE } from "../../../assets/constants/translates";
import { StatusType } from "../../../types/Application";

export const NewNote = () => {
  const { setActiveStep, note, setNote, person, application } = useContext(NewRequestContext);
  const [newNote, setNewNote] = useState<NewNoteType>({
    applicationId: application.id,
    date: note?.id >= 0 ? dayjs(note?.date, "YYYY-MM-DD") : dayjs(new Date()),
    recipient: note?.id ? note.recipient : "Al intendente municipal\nSr.: Silvio Miguel Sotelo\nS.................../...................D",
    body: note?.id ? note.body : "Me dirijo a usted",
  });

  const {
    data: newNoteData,
    loading: loadingPostNote,
    refetch: postNote,
  } = useAxios<NoteType>({
    url: application.note ? `${endpoints.newNote}/${note.id}` : endpoints.newNote,
    method: application.note ? "put" : "post",
    data: newNote,
    awaiting: true,
    msgOnSuccess: application.note ? "Datos actualizados" : "Datos guardados",
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      application.note &&
      application.status !== StatusType.IN_NOTE &&
      areValuesEqual(
        { ...application.note, date: dayjs(application.note?.date).format("DD/MM/YYYY") },
        { recipient: newNote.recipient, body: newNote.body, date: newNote.date?.format("DD/MM/YYYY") }
      )
    ) {
      setActiveStep(5);
    } else {
      postNote();
    }
  };

  useEffect(() => {
    if (newNoteData) {
      setNote(newNoteData);
      setActiveStep(5);
    }
  }, [newNoteData]);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Redacte o cargue el archivo de la nota
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gap={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DatePicker
              label="Fecha"
              value={newNote.date}
              onChange={(newDate) => handleChangeValue("date", newDate, setNewNote)}
              localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
              slotProps={{ textField: { required: true }, openPickerButton: { tabIndex: -1 } }}
            />
          </LocalizationProvider>
          <Typography variant="subtitle1" textAlign="right">
            {"San Bernardo, Chaco - " + new Date(newNote.date as unknown as string).toLocaleDateString("es-AR", fullDate)}
          </Typography>
          <TextField
            value={newNote.recipient}
            onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewNote)}
            required
            name="recipient"
            label="Destinatario"
            fullWidth
            multiline
            minRows={3}
          />
          <TextField
            value={newNote.body}
            onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewNote)}
            required
            name="body"
            label="Cuerpo de la nota"
            fullWidth
            multiline
            minRows={10}
          />
          <br />
          <Box display="flex" flexDirection="column" alignSelf="flex-end" textAlign="center">
            <strong>........................................................</strong>
            <strong>{`${person.lastName.toLocaleUpperCase()} ${person.name.toLocaleUpperCase()}`}</strong>
            <strong>{`${(DNI_TYPE.find((value) => value.value === person.dniType)?.text as string).toLocaleUpperCase()}: ${person.dni}`}</strong>
            <strong>{person.phoneNumber && `CEL: ${person.phoneNumber}`}</strong>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              setActiveStep((activeStep) => activeStep - 1);
            }}
            sx={{ mt: 3, ml: 1 }}
            tabIndex={-1}
          >
            Atrás
          </Button>
          <LoadingButton loading={loadingPostNote} variant="contained" type="submit" sx={{ mt: 3, ml: 1 }}>
            Siguiente
          </LoadingButton>
        </Box>
      </form>
    </React.Fragment>
  );
};
