// const baseUrl = `http://localhost:3002/api`;
// const baseUrl = `http://192.168.1.4:3002/api`;
const baseUrl = "https://032d-190-227-22-213.ngrok-free.app/api";

const endpoints = {
  login: `${baseUrl}/auth/login`, // [POST]

  getDataUser: `${baseUrl}/users/me`, // [GET]

  getPersons: `${baseUrl}/persons`, // [GET]
  getPersonById: `${baseUrl}/persons`, // [GET]
  uploadPerson: `${baseUrl}/persons`, // [POST]
  updatePerson: `${baseUrl}/persons`, // [PUT]

  getRejecteds: `${baseUrl}/rejecteds`, // [GET]
  getMyRejecteds: `${baseUrl}/rejecteds/mine`, // [GET]
  getRejectedById: `${baseUrl}/rejecteds`, // [GET]
  uploadRejected: `${baseUrl}/rejecteds`, // [POST]

  getApplications: `${baseUrl}/applications`, // [GET]
  getApplicationsByParams: `${baseUrl}/applications/search`, // [GET]
  getApplicationsInProgress: `${baseUrl}/applications/complete`, // [GET]
  getMyApplicationsInProgress: `${baseUrl}/applications/mine`, // [GET]
  getApplicationById: `${baseUrl}/applications`, // [GET]
  getLastApplicationByPersonId: `${baseUrl}/applications/person`, // [GET]
  newApplication: `${baseUrl}/applications`, // [POST]
  updateApplication: `${baseUrl}/applications`, // [PUT]
  completeApplication: `${baseUrl}/applications/complete`, // [PUT]
  deleteApplications: `${baseUrl}/applications`, // [DELETE]

  getJobs: `${baseUrl}/jobs`, // [GET]
  newJob: `${baseUrl}/jobs`, // [POST]
  updateJob: `${baseUrl}/jobs`, // [PUT]

  newForm: `${baseUrl}/forms`, // [POST]
  updateForm: `${baseUrl}/forms`, // [PUT]
  updateDwelling: `${baseUrl}/forms/datos-vivienda`, // [PUT]

  getCohabitantsByFormId: `${baseUrl}/cohabitants/form`, // [GET]
  addCohabitants: `${baseUrl}/cohabitants`, // [POST]
  updateCohabitants: `${baseUrl}/cohabitants`, // [PUT]
  deleteCohabitants: `${baseUrl}/cohabitants/delete`, // [POST]

  newNote: `${baseUrl}/notes`, // [POST]

  resourceBase: `${baseUrl}/resources`,
  downloadFile: `${baseUrl}/resources/descargar-archivo`,
  getResourcesByApplicationId: `${baseUrl}/resources?application`,
  uploadFile: `subir-archivos`, // [POST]
  deleteFile: `borrar-archivo`, // [DELETE]
  getLocation: `ruta`, // [GET]

  getTotalStats: `${baseUrl}/statistics/total/solicitudes`, // [GET]
  reports: `${baseUrl}/statistics/reporte`, // [GET]
  otherReport: `${baseUrl}/statistics/reporte/otros`, // [GET]
};
export default endpoints;
