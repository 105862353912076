import React, { FunctionComponent } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";

type DialogTypeProps = {
  open: boolean;
  handleClose: any;
  handleSubmit: any;
  fieldsDialog: {
    title?: string;
    accept?: string;
    description?: string;
    iconTitle?: React.ReactNode;
    acceptDisabled?: boolean;
  };
  children?: React.ReactNode;
  loading: boolean;
};

export const ModalNew: FunctionComponent<DialogTypeProps> = ({ open, handleClose, handleSubmit, fieldsDialog, children, loading }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
        {fieldsDialog?.iconTitle}
        {fieldsDialog?.title}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {fieldsDialog?.description && <DialogContentText>{fieldsDialog?.description}</DialogContentText>}
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <LoadingButton type="submit" loading={loading} disabled={fieldsDialog?.acceptDisabled}>
            {fieldsDialog?.accept}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
