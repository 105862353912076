import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export function convertToDate(date: string) {
  return [dayjs(date).format("DD/MM/YYYY"), dayjs(date).format("HH:mm")];
}

export function convertToFullDate(date: string) {
  return dayjs(date).format("DD/MM/YYYY, HH:mm");
}

export function ordenarPorFechaDescendente<T extends { date: string }>(data: T[]): T[] {
  return data.sort((a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();

    return dateB - dateA;
  });
}
