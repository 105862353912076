import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Delete, AddCircle, Add } from "@mui/icons-material";
import dayjs from "dayjs";

import useAxios from "../../../hooks/useAxios";
import endpoints from "../../../helpers/endpoints";
import { NewRequestContext } from "../../../contexts/NewRequestContext";
import { areValuesEqual, handleChangeArrayValue, regexFloat } from "../../../helpers/functions";
import { DNI_TYPE, EDUCATION, RELATIONSHIP } from "../../../assets/constants/translates";
import { CohabitantType, NewCohabitantType } from "../../../types/Cohabitant";
import { JobType } from "../../../types/Job";
import { NewJob } from "../../../components/NewJob";
import { InputDate } from "../../../components/Inputs";

export const AddCohabitants = () => {
  const { setActiveStep, setCohabitants, cohabitants, form, application } = useContext(NewRequestContext);
  const [newCohabitants, setNewCohabitants] = useState<NewCohabitantType[]>(
    cohabitants.map((c) => {
      return { ...c, jobId: c.job.id, formId: form.id, birthDate: dayjs(c.birthDate, "YYYY-MM-DD") };
    })
  );
  const [newCohabitantsToAdd, setNewCohabitantsToAdd] = useState<NewCohabitantType[]>([]);
  const [eraseCohabitants, setEraseCohabitants] = useState<number[]>([]);
  const [updateCohabitants, setUpdateCohabitants] = useState<NewCohabitantType[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { data: jobs, loading: loadingJobs, refetch: refetchJobs } = useAxios<JobType[]>({ url: endpoints.getJobs, initialData: [] });

  const addItem = (index: number) => {
    const arrItems = [...newCohabitants];
    arrItems.splice(index + 1, 0, {
      formId: form.id,
      name: "",
      lastName: "",
      dniType: "dni",
      dni: "",
      birthDate: null,
      relationship: "",
      education: "",
      jobId: null,
      earnings: "",
    });
    setNewCohabitants(arrItems);
  };
  const removeItem = (index: number) => {
    const arrItems = [...newCohabitants];
    arrItems.splice(index, 1);
    setNewCohabitants(arrItems);
  };
  const {
    data: newCohabitantsData,
    loading: loadingPostCohabitants,
    refetch: postCohabitants,
  } = useAxios<CohabitantType[]>({
    url: endpoints.addCohabitants,
    method: "post",
    data: newCohabitantsToAdd,
    awaiting: true,
    msgOnSuccess: newCohabitants.length > 0 ? "Convivientes añadidos" : "Datos guardados",
  });
  const {
    data: erasedCohabitantsData,
    loading: loadingDelete,
    refetch: deleteCohabitants,
  } = useAxios<{ affected: number; raw: any }>({
    url: endpoints.deleteCohabitants,
    method: "post",
    data: eraseCohabitants,
    awaiting: true,
    msgOnSuccess: "Convivientes eliminados",
  });
  const {
    data: updatedCohabitantsData,
    loading: loadingUpdate,
    refetch: putCohabitants,
  } = useAxios<CohabitantType[]>({
    url: endpoints.updateCohabitants,
    method: "put",
    data: updateCohabitants,
    awaiting: true,
    msgOnSuccess: "Convivientes editados",
  });
  const {
    data: cohabitantsData,
    loading: loadingGet,
    refetch: getCohabitants,
  } = useAxios<CohabitantType[]>({
    url: `${endpoints.getCohabitantsByFormId}/${form.id}`,
    method: "get",
    awaiting: true,
  });

  function isCohabitantInArray(cohabitant: CohabitantType | NewCohabitantType, cohabitantsArray: CohabitantType[] | NewCohabitantType[]): boolean {
    return cohabitantsArray.some((c) => c.dni === cohabitant.dni);
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (newCohabitants.length === 0) {
      setActiveStep(3);
    } else {
      const cohabitantsToAdd: NewCohabitantType[] = [];
      const cohabitantsToRemove: number[] = [];
      const cohabitantsToUpdate: NewCohabitantType[] = [];

      for (const newCohabitant of newCohabitants) {
        if (!isCohabitantInArray(newCohabitant, cohabitants)) {
          cohabitantsToAdd.push({ ...newCohabitant, earnings: newCohabitant.earnings ? newCohabitant.earnings : 0 });
        } else {
          const existingCohabitant = cohabitants.find((c) => c.dni === newCohabitant.dni);

          if (
            existingCohabitant &&
            !areValuesEqual(
              { ...newCohabitant, birthDate: newCohabitant.birthDate?.format("DD/MM/YYYY") },
              {
                ...existingCohabitant,
                formId: form.id,
                jobId: existingCohabitant.job.id,
                birthDate: dayjs(existingCohabitant.birthDate).format("DD/MM/YYYY"),
              }
            )
          ) {
            cohabitantsToUpdate.push({ ...newCohabitant, earnings: newCohabitant.earnings ? newCohabitant.earnings : 0 });
          }
        }
      }

      for (const existingCohabitant of cohabitants) {
        if (!isCohabitantInArray(existingCohabitant, newCohabitants)) {
          cohabitantsToRemove.push(existingCohabitant.id);
        }
      }

      if (cohabitantsToAdd.length + cohabitantsToRemove.length + cohabitantsToUpdate.length === 0) {
        setActiveStep(3);
      }

      setNewCohabitantsToAdd(cohabitantsToAdd);
      setEraseCohabitants(cohabitantsToRemove);
      setUpdateCohabitants(cohabitantsToUpdate);
    }
  };

  useEffect(() => {
    if (newCohabitantsToAdd.length > 0) {
      postCohabitants();
    }
  }, [newCohabitantsToAdd]);
  useEffect(() => {
    if (eraseCohabitants.length > 0) {
      deleteCohabitants();
    }
  }, [eraseCohabitants]);
  useEffect(() => {
    if (updateCohabitants.length > 0) {
      putCohabitants();
    }
  }, [updateCohabitants]);

  useEffect(() => {
    if (
      (newCohabitants.length > 0 && newCohabitantsData) ||
      (eraseCohabitants.length > 0 && erasedCohabitantsData) ||
      (updateCohabitants.length > 0 && updatedCohabitantsData)
    ) {
      getCohabitants();
    }
  }, [newCohabitantsData, erasedCohabitantsData, updatedCohabitantsData]);

  useEffect(() => {
    if (cohabitantsData.length > 0) {
      setCohabitants(cohabitantsData);
      setActiveStep(3);
    }
  }, [cohabitantsData]);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Añada a los convivientes del titular
        {newCohabitants.length === 0 && (
          <Tooltip title="Agregar">
            <IconButton onClick={() => addItem(0)} tabIndex={-1}>
              <AddCircle color="success" />
            </IconButton>
          </Tooltip>
        )}
      </Typography>
      {newCohabitants.length === 0 && <Alert severity="info">El ciudadano no tiene convivientes en su grupo familiar</Alert>}
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gap={2}>
          {newCohabitants.map((familiar, index) => (
            <React.Fragment key={index}>
              <Divider />
              <Grid container spacing={1} columnSpacing={1}>
                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth>
                    <InputLabel id="dni-type">Tipo de Documento</InputLabel>
                    <Select
                      labelId="dni-type"
                      value={familiar.dniType}
                      name="dniType"
                      label="Tipo de Documento"
                      required
                      onChange={(e) => {
                        handleChangeArrayValue("dni", "", index, newCohabitants, setNewCohabitants);
                        handleChangeArrayValue(e.target.name as keyof NewCohabitantType, e.target.value, index, newCohabitants, setNewCohabitants);
                      }}
                      // disabled={loadingGetCohabitant}
                    >
                      {DNI_TYPE.map((dni) => (
                        <MenuItem key={dni.value} value={dni.value}>
                          {dni.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={1.5}>
                  <TextField
                    value={familiar.dni}
                    onChange={(e) => {
                      if (familiar.dniType === "dni" && e.target.value.match("[^0-9]")) {
                        return;
                      }
                      handleChangeArrayValue(e.target.name as keyof NewCohabitantType, e.target.value, index, newCohabitants, setNewCohabitants);
                    }}
                    inputProps={familiar.dniType === "dni" ? { minLength: 7, maxLength: 8 } : undefined}
                    // InputProps={{
                    //   endAdornment: loadingGetCohabitant && (
                    //     <InputAdornment position="end">
                    //       <CircularProgress />
                    //     </InputAdornment>
                    //   ),
                    // }}
                    name="dni"
                    label="Documento"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth>
                    <InputLabel required id="relationship">
                      Parentesco
                    </InputLabel>
                    <Select
                      labelId="relationship"
                      value={familiar.relationship}
                      name="relationship"
                      label="Parentesco"
                      required
                      onChange={(e) =>
                        handleChangeArrayValue(e.target.name as keyof NewCohabitantType, e.target.value, index, newCohabitants, setNewCohabitants)
                      }
                    >
                      {RELATIONSHIP.map((relationship) => (
                        <MenuItem key={relationship.value} value={relationship.value}>
                          {relationship.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <Box mb={3}>
                    <TextField
                      value={familiar.name}
                      onChange={(e) =>
                        handleChangeArrayValue(e.target.name as keyof NewCohabitantType, e.target.value, index, newCohabitants, setNewCohabitants)
                      }
                      required
                      name="name"
                      label="Nombre/s"
                      fullWidth
                      // disabled={loadingGetCohabitant || (Array.isArray(personDataByDni) && personDataByDni.length !== 0)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box mb={3}>
                    <TextField
                      value={familiar.lastName}
                      onChange={(e) =>
                        handleChangeArrayValue(e.target.name as keyof NewCohabitantType, e.target.value, index, newCohabitants, setNewCohabitants)
                      }
                      required
                      name="lastName"
                      label="Apellido/s"
                      fullWidth
                      // disabled={loadingGetCohabitant || (Array.isArray(personDataByDni) && personDataByDni.length !== 0)}
                    />
                  </Box>
                </Grid>
                <InputDate
                  grid={{ lg: 2 }}
                  entity="cohabitant"
                  name="birthDate"
                  setValue={setNewCohabitants}
                  value={familiar.birthDate}
                  type="row"
                  index={index}
                  req
                />
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel required id="education">
                      Nivel educativo alcanzado
                    </InputLabel>
                    <Select
                      labelId="education"
                      value={familiar.education}
                      name="education"
                      label="Nivel educativo alcanzado"
                      required
                      onChange={(e) =>
                        handleChangeArrayValue(e.target.name as keyof NewCohabitantType, e.target.value, index, newCohabitants, setNewCohabitants)
                      }
                    >
                      {EDUCATION.map((education) => (
                        <MenuItem key={education.value} value={education.value}>
                          {education.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} display="flex">
                  <Autocomplete
                    loading={loadingJobs}
                    options={jobs}
                    getOptionLabel={(option) => option.name}
                    value={jobs.find((job) => job.id === familiar.jobId) || null}
                    onChange={(event: React.SyntheticEvent<Element, Event>, newValue: JobType | null) => {
                      handleChangeArrayValue("jobId" as keyof NewCohabitantType, newValue?.id, index, newCohabitants, setNewCohabitants);
                    }}
                    sx={{ width: "100%" }}
                    openOnFocus
                    autoHighlight
                    autoSelect
                    renderInput={(params) => <TextField {...params} label="Ocupación" required />}
                  />
                  <Tooltip title="Nueva ocupación">
                    <IconButton onClick={() => setOpenModal(true)} color="primary">
                      <Add />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    label="Ingreso mensual"
                    name="earnings"
                    value={familiar.earnings}
                    onChange={(e) => {
                      if (regexFloat.test(e.target.value)) {
                        handleChangeArrayValue("earnings" as keyof NewCohabitantType, e.target.value, index, newCohabitants, setNewCohabitants);
                      }
                    }}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={2} display="flex" alignItems="center" width="100%">
                  <Tooltip title="Agregar">
                    <IconButton onClick={() => addItem(index)} tabIndex={-1}>
                      <AddCircle color="success" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Borrar">
                    <IconButton onClick={() => removeItem(index)} tabIndex={-1}>
                      <Delete color="error" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              setActiveStep((activeS) => activeS - 1);
            }}
            sx={{ mt: 3, ml: 1 }}
            tabIndex={-1}
          >
            Atrás
          </Button>
          <LoadingButton
            loading={loadingPostCohabitants || loadingDelete || loadingUpdate || loadingGet}
            variant="contained"
            type="submit"
            sx={{ mt: 3, ml: 1 }}
          >
            Siguiente
          </LoadingButton>
        </Box>
      </form>
      <NewJob open={openModal} setOpen={setOpenModal} refetch={refetchJobs} jobs={jobs} />
    </React.Fragment>
  );
};
