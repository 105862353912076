import React, { useRef, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { jsonWebToken } from "../helpers/auth";
import { useLocation } from "react-router";
import { displayToast } from "../helpers/toast";

export const PublicRoute = () => {
  const isAuthenticated = jsonWebToken();
  const { state } = useLocation();
  const flag = useRef(true);

  useEffect(() => {
    if (state && flag.current) {
      displayToast(state);
      window.history.replaceState(null, "");
      flag.current = false;
    }
  }, [state]);

  return isAuthenticated ? <Navigate to="/dashboard" replace /> : <Outlet />;
};
