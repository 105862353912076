import React, { useEffect, useState, useCallback } from "react";
import { useParams, useLocation, useNavigate } from "react-router";
import { Paper, Typography, Box, CircularProgress, Link, Modal, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import { useApiQuery } from "../../helpers/apiClient";
import endpoints from "../../helpers/endpoints";
import { StatisticReportType } from "../../types/Statistic";
import { BaseDataGrid } from "../../components/BaseDataGrid";
import { CATEGORY } from "../../assets/constants/translates";
import { paperStyle } from "../../assets/styles/Styles";
import { Category_N } from "../../types/Application";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { formatToCurrency } from "../../helpers/functions";

interface OtherItem {
  unit: string;
  count: number;
  total: number;
  ids: string[];
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  width: "80%",
  maxWidth: "90vw",
  height: "90%",
  p: 4,
  borderRadius: 1,
};

const otherColumns: GridColDef[] = [
  {
    field: "unit",
    headerName: "Unidad",
    description: "Unidad de medida",
    minWidth: 200,
    flex: 50,
  },
  {
    field: "count",
    headerName: "Solicitudes",
    description: "Número de solicitudes",
    minWidth: 50,
    flex: 10,
    align: "right",
  },
  {
    field: "total",
    headerName: "Total",
    description: "Cantidad total",
    minWidth: 50,
    flex: 10,
    align: "right",
  },
  {
    field: "ids",
    headerName: "Solicitudes",
    description: "IDs de las solicitudes",
    minWidth: 200,
    flex: 30,
    renderCell: (params) => <IdsCellRenderer params={params} />,
  },
];

const IdsCellRenderer: React.FC<{ params: any }> = React.memo(({ params }) => {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

  const updateButtonsVisibility = useCallback(() => {
    if (scrollContainerRef.current) {
      setShowLeftButton(scrollContainerRef.current.scrollLeft > 0);
      setShowRightButton(scrollContainerRef.current.scrollWidth > scrollContainerRef.current.clientWidth);
    }
  }, []);

  const handleScroll = useCallback((direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = 100;
      const newScrollLeft = direction === "left" ? scrollContainerRef.current.scrollLeft - scrollAmount : scrollContainerRef.current.scrollLeft + scrollAmount;
      scrollContainerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    updateButtonsVisibility();
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", updateButtonsVisibility);
      return () => scrollContainer.removeEventListener("scroll", updateButtonsVisibility);
    }
  }, [updateButtonsVisibility]);

  const renderLink = useCallback(
    (id: string) => (
      <React.Fragment key={id}>
        <Link
          underline="hover"
          href={`/solicitud/${id}`}
          target="_blank"
          sx={{
            display: "inline-block",
            color: "primary.main",
          }}
        >
          {id}
        </Link>
        &nbsp;
      </React.Fragment>
    ),
    []
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: 1,
      }}
    >
      <IconButton
        size="small"
        onClick={() => handleScroll("left")}
        sx={{
          padding: "2px",
          visibility: showLeftButton ? "visible" : "hidden",
        }}
      >
        <KeyboardArrowLeft />
      </IconButton>

      <Box
        ref={scrollContainerRef}
        sx={{
          overflowX: "auto",
          width: "100%",
          whiteSpace: "nowrap",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {params.row.ids.map(renderLink)}
      </Box>

      <IconButton
        size="small"
        onClick={() => handleScroll("right")}
        sx={{
          padding: "2px",
          visibility: showRightButton ? "visible" : "hidden",
        }}
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
});

export const Report = () => {
  const columns: GridColDef[] = [
    {
      field: "category",
      headerName: "Categoría",
      description: "Categoría de la solicitud",
      minWidth: 200,
      flex: 30,
      renderCell: (params) => CATEGORY[params.row.category as Category_N] || "",
    },
    {
      field: "total",
      headerName: "Total solicitudes",
      description: "Cantidad total de solicitudes",
      minWidth: 100,
      flex: 20,
    },
    {
      field: "totalMoney",
      headerName: "Pesos",
      description: "Monto total otorgado en dinero y cantidad de solicitudes",
      minWidth: 150,
      flex: 30,
      renderCell: ({ row }: { row: any }) => {
        return row.totalMoneyCount > 0 ? (
          <>
            {formatToCurrency.format(row.totalMoney)} ({<Link href={`/solicitudes/${year}/${row.category}/pesos`}>{row.totalMoneyCount} solicitudes</Link>})
          </>
        ) : (
          `${formatToCurrency.format(row.totalMoney)} (${row.totalMoneyCount} solicitudes)`
        );
      },
    },
    {
      field: "totalFuel",
      headerName: "Litros de combustible",
      description: "Cantidad total de combustible otorgado y cantidad de solicitudes",
      minWidth: 120,
      flex: 5,
      renderCell: ({ row }: { row: any }) => {
        return row.totalFuelCount > 0 ? (
          <>
            {row.totalFuel}L ({<Link href={`/solicitudes/${year}/${row.category}/litros-de-combustible`}>{row.totalFuelCount}</Link>})
          </>
        ) : (
          `${row.totalFuel}L (${row.totalFuelCount})`
        );
      },
    },
    {
      field: "totalCake",
      headerName: "Kilos de torta",
      description: "Cantidad total de tortas otorgadas y cantidad de solicitudes",
      minWidth: 120,
      flex: 5,
      renderCell: ({ row }: { row: any }) => {
        return row.totalCakeCount > 0 ? (
          <>
            {row.totalCake}Kg ({<Link href={`/solicitudes/${year}/${row.category}/kilos-de-torta`}>{row.totalCakeCount}</Link>})
          </>
        ) : (
          `${row.totalCake}Kg (${row.totalCakeCount})`
        );
      },
    },
    {
      field: "totalMeat",
      headerName: "Kilos de carne",
      description: "Cantidad total de carne otorgada y cantidad de solicitudes",
      minWidth: 120,
      flex: 5,
      renderCell: ({ row }: { row: any }) => {
        return row.totalMeatCount > 0 ? (
          <>
            {row.totalMeat}Kg ({<Link href={`/solicitudes/${year}/${row.category}/kilos-de-carne`}>{row.totalMeatCount}</Link>})
          </>
        ) : (
          `${row.totalMeat}Kg (${row.totalMeatCount})`
        );
      },
    },
    {
      field: "totalOthers",
      headerName: "Otros",
      description: "Otras unidades cargadas",
      minWidth: 120,
      flex: 5,
      renderCell: ({ row }: { row: any }) => {
        return row.totalOthers > 0 ? <Link href={`#${row.category}`}>Otros ({row.totalOthers})</Link> : `Otros (${row.totalOthers})`;
      },
    },
  ];

  const { year } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const { data, isFetching } = useApiQuery<StatisticReportType[]>(["report"], `${endpoints.reports}?year=${year === "total" ? "" : year}`);
  const {
    data: otherData,
    isFetching: isOtherFetching,
    isSuccess,
  } = useApiQuery<OtherItem[]>(
    ["otherReport-" + selectedCategory],
    selectedCategory ? `${endpoints.otherReport}/${selectedCategory}?year=${year === "total" ? "" : year}` : "",
    { enabled: !!selectedCategory }
  );

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const category = hash.replace("#", "");
      setSelectedCategory(category);
      setOpenModal(true);
    } else {
      setSelectedCategory(null);
      setOpenModal(false);
    }
  }, [location.hash]);

  const handleCloseModal = () => {
    navigate(location.pathname + location.search, { replace: true });
  };

  return (
    <Paper sx={paperStyle}>
      <Typography variant="h4" gutterBottom>
        Reporte {year === "total" ? "Total" : `del año ${year}`}
      </Typography>
      {isFetching ? (
        <Box display="flex" justifyContent="center" my={3}>
          <CircularProgress />
        </Box>
      ) : (
        <BaseDataGrid rows={data || []} columns={columns} getRowId={(row) => row.category} />
      )}

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Otras solicitudes - {selectedCategory && CATEGORY[selectedCategory as Category_N]}
          </Typography>
          {isOtherFetching ? (
            <Box display="flex" justifyContent="center" my={3}>
              <CircularProgress />
            </Box>
          ) : (
            isSuccess && (
              <Box sx={{ height: 400, width: "100%" }}>
                <BaseDataGrid
                  rows={otherData || []}
                  columns={otherColumns}
                  getRowId={(row) => row.unit?.replace(" ", "_")}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[10]}
                />
              </Box>
            )
          )}
        </Box>
      </Modal>
    </Paper>
  );
};
