import React, { useEffect } from "react";
import { Alert } from "@mui/lab";
import { displayToast } from "../helpers/toast";

export const Dashboard = () => {
  useEffect(() => {
    const msg = window.localStorage.getItem("welcome");
    if (msg) {
      displayToast({ message: msg, statusCode: 200 });
      window.localStorage.removeItem("welcome");
    }
  }, []);

  return <Alert sx={{ width: "100%" }}>¡Bienvenido!</Alert>;
};
