import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  Button,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Add } from "@mui/icons-material";

import { areValuesEqual, handleChangeValue, regexFloat } from "../../../helpers/functions";
import { EDUCATION, MARITAL_STATUS } from "../../../assets/constants/translates";
import { NewRequestContext } from "../../../contexts/NewRequestContext";
import useAxios from "../../../hooks/useAxios";
import endpoints from "../../../helpers/endpoints";
import { JobType } from "../../../types/Job";
import { InitialFormType, NewFormType } from "../../../types/Form";
import { ApplicationType, CreateApplicationType } from "../../../types/Application";
import { NewJob } from "../../../components/NewJob";

const initialForm: NewFormType = {
  address: "",
  maritalStatus: "",
  jobId: null,
  earnings: "",
  workplace: "",
  education: "",
};

export default function NewApplication() {
  const { idApplication } = useParams();
  let navigate = useNavigate();

  const { setActiveStep, form, setForm, person, application, setApplication } = useContext(NewRequestContext);
  const [newForm, setNewForm] = useState<NewFormType>(initialForm);
  const [newApplication, setNewApplication] = useState<CreateApplicationType>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [nameJob, setNameJob] = useState("");
  const [openJob, setOpenJob] = useState(false);
  const { data: jobs, refetch: refetchJobs, loading: loadingJobs } = useAxios<JobType[]>({ url: endpoints.getJobs, initialData: [] });

  const {
    data: newFormData,
    loading: loadingPostForm,
    refetch: postForm,
  } = useAxios<InitialFormType>({
    url: idApplication ? `${endpoints.newForm}/${form.id}` : endpoints.newForm,
    method: idApplication ? "put" : "post",
    data: newForm,
    awaiting: true,
    msgOnSuccess: idApplication ? "Datos actualizados" : "Datos guardados",
  });
  const { data: newApplicationData, loading: loadingPostApplication } = useAxios<ApplicationType>({
    url: endpoints.newApplication,
    method: "post",
    data: newApplication,
    msgOnSuccess: "Solicitud comenzada",
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (form.id !== -1 && form.id && areValuesEqual({ ...form, jobId: form.job.id }, newForm)) {
      setActiveStep(2);
    } else {
      postForm();
    }
  };

  useEffect(() => {
    if (newFormData) {
      setForm(newFormData);
      if (idApplication) {
        setActiveStep(2);
      } else {
        setNewApplication({ formId: newFormData.id, personId: person.id });
      }
    }
  }, [newFormData]);

  useEffect(() => {
    if (newApplicationData) {
      if (Object.keys(application).length) {
        setApplication({
          ...newApplicationData,
          category: application.category,
          problematic: application.problematic,
          observation: application.observation,
          amount: application.amount,
          unit: application.unit,
          isAccountable: application.isAccountable,
        });
      } else {
        setApplication(newApplicationData);
      }
      navigate("./" + newApplicationData.id);
      setActiveStep(2);
    }
  }, [newApplicationData]);

  useEffect(() => {
    if (form.id) {
      setNewForm({
        address: form.address,
        maritalStatus: form.maritalStatus,
        jobId: form.job.id,
        earnings: form.earnings,
        workplace: form.workplace,
        education: form.education,
      });
    }
  }, [form]);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Complete con los datos personales
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              value={newForm.address}
              onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewForm)}
              required
              name="address"
              label="Domicilio"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel required id="marital-status">
                Estado Civil
              </InputLabel>
              <Select
                labelId="marital-status"
                value={newForm.maritalStatus}
                name="maritalStatus"
                label="Estado civil"
                required
                onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewForm)}
              >
                {MARITAL_STATUS.map((maritalStatus) => (
                  <MenuItem key={maritalStatus.value} value={maritalStatus.value}>
                    {maritalStatus.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel required id="education">
                Nivel educativo alcanzado
              </InputLabel>
              <Select
                labelId="education"
                value={newForm.education}
                name="education"
                label="Nivel educativo alcanzado"
                required
                onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewForm)}
              >
                {EDUCATION.map((education) => (
                  <MenuItem key={education.value} value={education.value}>
                    {education.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} display="flex">
            <Autocomplete
              loading={loadingJobs}
              options={jobs}
              getOptionLabel={(option) => option.name}
              value={jobs[jobs.findIndex((job) => job.id === newForm.jobId)] || null}
              onChange={(event: React.SyntheticEvent<Element, Event>, newValue: JobType | null) => {
                if (newValue) {
                  handleChangeValue("jobId", newValue.id, setNewForm);
                }
              }}
              sx={{ width: "100%" }}
              openOnFocus
              autoHighlight
              disableClearable
              onOpen={() => setOpenJob(true)}
              onClose={() => setOpenJob(false)}
              renderInput={(params) => <TextField {...params} label="Ocupación" required />}
            />
            <Tooltip title="Nueva ocupación">
              <IconButton onClick={() => setOpenModal(true)} color="primary">
                <Add />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={newForm.workplace}
              onChange={(e) => handleChangeValue(e.target.name, e.target.value, setNewForm)}
              name="workplace"
              label="Lugar de trabajo"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Ingreso mensual"
              name="earnings"
              value={newForm.earnings}
              onChange={(e) => {
                if (regexFloat.test(e.target.value)) {
                  handleChangeValue("earnings", e.target.value, setNewForm);
                }
              }}
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              setActiveStep((activeStep) => activeStep - 1);
            }}
            sx={{ mt: 3, ml: 1 }}
            tabIndex={-1}
          >
            Atrás
          </Button>
          <LoadingButton loading={loadingPostForm || loadingPostApplication} variant="contained" type="submit" sx={{ mt: 3, ml: 1 }} disabled={openJob}>
            Siguiente
          </LoadingButton>
        </Box>
      </form>
      {openModal && <NewJob open={openModal} setOpen={setOpenModal} refetch={refetchJobs} setData={setNewForm} jobs={jobs} setNameJob={setNameJob} />}
    </React.Fragment>
  );
}
