import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Paper, Typography, Box, Tooltip, Tab } from "@mui/material";
import { GridColDef, GridEventListener } from "@mui/x-data-grid";

import useAxios from "../../../hooks/useAxios";
import endpoints from "../../../helpers/endpoints";
import { RejectedType } from "../../../types/Rejected";
import { PersonType } from "../../../types/Person";
import { convertToDate } from "../../../helpers/dateFormat";
import { DNI_TYPE } from "../../../assets/constants/translates";
import { BaseDataGrid } from "../../../components/BaseDataGrid";
import { toNameFormat } from "../../../helpers/functions";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    description: "Código de solicitud",
    width: 60,
  },
  {
    field: "createdAt",
    headerName: "Fecha",
    description: "Fecha de entrada",
    width: 100,
    align: "center",
    headerAlign: "center",
    valueGetter: (value) => new Date(value),
    renderCell: (params) => {
      const [date, hour] = convertToDate(params.row?.createdAt);
      return (
        <Tooltip title={hour}>
          <span>{date}</span>
        </Tooltip>
      );
    },
  },
  {
    field: "description",
    headerName: "Descripción",
    description: "Descripción del motivo del rechazo",
    minWidth: 100,
    flex: 60,
  },
  {
    field: "person",
    headerName: "Ciudadano",
    description: "Persona",
    minWidth: 100,
    flex: 20,
    valueGetter: (value: PersonType) => {
      const { name, lastName, dni } = value;
      return `${lastName} ${name} - ${dni}`;
    },
    renderCell: (params: { row: RejectedType }) => {
      const { name, lastName, dniType, dni } = params.row?.person;
      return (
        <Tooltip title={`${DNI_TYPE.find((value) => value.value === dniType)?.text as string}: ${dni}`}>
          <span>{toNameFormat(`${lastName}, ${name}`)}</span>
        </Tooltip>
      );
    },
  },
  {
    field: "operator",
    headerName: "Operador",
    description: "Operador",
    minWidth: 100,
    flex: 20,
    renderCell: (params) => {
      const { name, lastName } = params.row?.operator;
      return `${lastName}, ${name}`;
    },
  },
];

export const Entries = () => {
  const [tab, setTab] = useState("1");
  const { data: entries, loading: loadingEntries } = useAxios<RejectedType[]>({
    url: endpoints.getRejecteds,
    initialData: [],
  });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Box width="100%">
      <Typography variant="h3">Entradas</Typography>
      <Paper sx={{ width: "100%" }} elevation={3}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList variant="fullWidth" onChange={handleChange}>
              <Tab label="Entradas" value="1" />
              <Tab label="Solicitudes rechazadas" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <EntryGrid entries={entries.filter((entry) => entry.reason === 0)} loadingEntries={loadingEntries} />
          </TabPanel>
          <TabPanel value="2">
            <EntryGrid entries={entries.filter((entry) => entry.reason !== 0)} loadingEntries={loadingEntries} />
          </TabPanel>
        </TabContext>
      </Paper>
    </Box>
  );
};

const EntryGrid = ({ entries, loadingEntries }: { entries: RejectedType[]; loadingEntries: boolean }) => {
  let navigate = useNavigate();

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    navigate("../entrada/" + params.row?.id);
  };

  return <BaseDataGrid rows={entries} columns={columns} loading={loadingEntries} onRowClick={handleRowClick} />;
};
