import React, { useContext, useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { ActualFileObject, FilePondInitialFile } from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css";

import { allowedFiles, propsFilepond } from "../assets/constants/filepond";
import endpoints from "../helpers/endpoints";
import { FileType, ResponseResource } from "../types/Resource";
import { authHeader } from "../helpers/auth";
import useAxios from "../hooks/useAxios";
import { NewRequestContext } from "../contexts/NewRequestContext";

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImagePreview, FilePondPluginFilePoster);
interface FileSubmitType {
  setFileLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fileName: string | null;
  type: FileType;
  maxFiles?: number;
}

export const FileSubmit = ({ setFileLoading, type, fileName, maxFiles = 1 }: FileSubmitType) => {
  const { application, person, setPerson } = useContext(NewRequestContext);
  const id = type === FileType.DNI_BACK || type === FileType.DNI_FRONT ? person.id : application.id;
  const route = type === FileType.DNI_BACK || type === FileType.DNI_FRONT ? "dni" : type;
  const [file, setFile] = useState<undefined | ActualFileObject>();
  const [fileToRemove, setFileToRemove] = useState<string | null>(null);
  const [fileAutoloaded, setFileAutoloaded] = useState<FilePondInitialFile[] | undefined>();
  const { headers } = authHeader();

  const {
    data: removeData,
    refetch: refetchRemove,
    error: errorRemove,
  } = useAxios<string>({
    url: `${endpoints.resourceBase}/${endpoints.deleteFile}/${id}/${fileToRemove}`,
    method: "delete",
    awaiting: true,
  });

  useEffect(() => {
    if (fileName) {
      setFileAutoloaded([
        {
          source: `${route}/${fileName}`,
          options: {
            type: "local",
            metadata: {
              poster: `${route}/${fileName}`,
            },
          },
        },
      ]);
    }
  }, [fileName]);

  useEffect(() => {
    if (removeData === "Archivo eliminado") {
      setFileAutoloaded([]);
    }
  }, [removeData]);

  useEffect(() => {
    if (fileToRemove) {
      refetchRemove();
    }
  }, [fileToRemove]);

  return (
    <FilePond
      credits={false}
      allowMultiple={maxFiles > 1}
      maxFiles={maxFiles}
      files={file ? [file] : fileAutoloaded}
      server={{
        url: `${endpoints.resourceBase}`,
        process: `/${endpoints.uploadFile}/${type}/${id}`,
        headers,
        revert: null,
        load: "/",
        restore: null,
        fetch: null,
        remove: null,
      }}
      onprocessfilestart={() => {
        setFileLoading(true);
      }}
      beforeRemoveFile={(item) => {
        if (item.status === 8) {
          return true;
        }
        if (item.origin === 1) {
          if (route !== "dni") {
            const response: ResponseResource = JSON.parse(item.serverId);
            if (response.success && response.files) {
              setFileToRemove(`${type}/${response.files[0].filename}`);
            }
          } else {
            setFileToRemove("dni/" + item.serverId);
          }
        } else {
          setFileToRemove(item.serverId);
        }
        setFile(undefined);
        return errorRemove === null;
      }}
      onprocessfile={(error, file) => {
        setFileAutoloaded([]);
        setFileLoading(false);
        setFile(file.file);
        if (type === FileType.DNI_FRONT) {
          setPerson({ ...person, dniFileFront: file.serverId });
        } else if (type === FileType.DNI_BACK) {
          setPerson({ ...person, dniFileBack: file.serverId });
        }
      }}
      onremovefile={() => {
        setFileToRemove(null);
        setFileAutoloaded([]);
        setFileLoading(false);
        if (type === FileType.DNI_FRONT) {
          setPerson({ ...person, dniFileFront: null });
        } else if (type === FileType.DNI_BACK) {
          setPerson({ ...person, dniFileBack: null });
        }
      }}
      onaddfilestart={() => {
        setFileLoading(true);
      }}
      onaddfile={(error, file) => {
        setFile(file.file);
        setFileAutoloaded([]);
        setFileLoading(false);
      }}
      onerror={() => setFileLoading(false)}
      allowFileTypeValidation={true}
      acceptedFileTypes={allowedFiles}
      allowFileSizeValidation={true}
      maxFileSize="20MB"
      name="files"
      {...propsFilepond}
    />
  );
};
